@font-face {
  font-family: 'neu';
  src: url('../font/neu.eot?9018335');
  src: url('../font/neu.eot?9018335#iefix') format('embedded-opentype'),
       url('../font/neu.woff2?9018335') format('woff2'),
       url('../font/neu.woff?9018335') format('woff'),
       url('../font/neu.ttf?9018335') format('truetype'),
       url('../font/neu.svg?9018335#neu') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'neu';
    src: url('../font/neu.svg?9018335#neu') format('svg');
  }
}
*/
 
 [class^="neu-"]:before, [class*=" neu-"]:before {
  font-family: "neu";
  font-style: normal;
  font-weight: normal;
  speak: never;
 
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */
 
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
 
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
 
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;
 
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
 
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
 
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}
 
.neu-so-couple:before { content: '\21'; } /* '!' */
.neu-so-blind-eyes:before { content: '\22'; } /* '&quot;' */
.neu-so-accessibility:before { content: '\23'; } /* '#' */
.neu-so-enter-people:before { content: '\24'; } /* '$' */
.neu-so-escalator-down:before { content: '\25'; } /* '%' */
.neu-so-escalator-up:before { content: '\26'; } /* '&amp;' */
.neu-so-eye-plus:before { content: '\27'; } /* ''' */
.neu-so-walk:before { content: '\28'; } /* '(' */
.neu-so-apartment:before { content: '\29'; } /* ')' */
.neu-so-building:before { content: '\2a'; } /* '*' */
.neu-so-cabin-house:before { content: '\2b'; } /* '+' */
.neu-so-church:before { content: '\2c'; } /* ',' */
.neu-so-city:before { content: '\2d'; } /* '-' */
.neu-so-company:before { content: '\2e'; } /* '.' */
.neu-so-factory:before { content: '\2f'; } /* '/' */
.neu-so-landmark:before { content: '\30'; } /* '0' */
.neu-so-monument:before { content: '\31'; } /* '1' */
.neu-so-mosque:before { content: '\32'; } /* '2' */
.neu-so-mosque-2:before { content: '\33'; } /* '3' */
.neu-so-office:before { content: '\34'; } /* '4' */
.neu-so-park:before { content: '\35'; } /* '5' */
.neu-so-police-station:before { content: '\36'; } /* '6' */
.neu-so-real-estate:before { content: '\37'; } /* '7' */
.neu-so-skyscrapers:before { content: '\38'; } /* '8' */
.neu-so-temple:before { content: '\39'; } /* '9' */
.neu-so-torii-gate:before { content: '\3a'; } /* ':' */
.neu-so-brush:before { content: '\3b'; } /* ';' */
.neu-so-cube:before { content: '\3c'; } /* '&lt;' */
.neu-so-divide:before { content: '\3d'; } /* '=' */
.neu-so-eclipse-1:before { content: '\3e'; } /* '&gt;' */
.neu-so-eyedropper-tool:before { content: '\3f'; } /* '?' */
.neu-so-file-structure:before { content: '\40'; } /* '@' */
.neu-so-folder-structure:before { content: '\41'; } /* 'A' */
.neu-so-h-align-center:before { content: '\42'; } /* 'B' */
.neu-so-h-align-left:before { content: '\43'; } /* 'C' */
.neu-so-h-align-right:before { content: '\44'; } /* 'D' */
.neu-so-hue-saturation:before { content: '\45'; } /* 'E' */
.neu-so-img:before { content: '\46'; } /* 'F' */
.neu-so-intersect:before { content: '\47'; } /* 'G' */
.neu-so-magic-wand:before { content: '\48'; } /* 'H' */
.neu-so-magnet:before { content: '\49'; } /* 'I' */
.neu-so-mathematical-anchor:before { content: '\4a'; } /* 'J' */
.neu-so-mesh-fill:before { content: '\4b'; } /* 'K' */
.neu-so-palette:before { content: '\4c'; } /* 'L' */
.neu-so-pencil:before { content: '\4d'; } /* 'M' */
.neu-so-pen-tool:before { content: '\4e'; } /* 'N' */
.neu-so-picture:before { content: '\4f'; } /* 'O' */
.neu-so-polygon:before { content: '\50'; } /* 'P' */
.neu-so-prism:before { content: '\51'; } /* 'Q' */
.neu-so-rectangle:before { content: '\52'; } /* 'R' */
.neu-so-rounded-rectangle:before { content: '\53'; } /* 'S' */
.neu-so-ruler:before { content: '\54'; } /* 'T' */
.neu-so-ruler-pencil:before { content: '\55'; } /* 'U' */
.neu-so-structure:before { content: '\56'; } /* 'V' */
.neu-so-transform:before { content: '\57'; } /* 'W' */
.neu-so-triangle:before { content: '\58'; } /* 'X' */
.neu-so-trim:before { content: '\59'; } /* 'Y' */
.neu-so-tube:before { content: '\5a'; } /* 'Z' */
.neu-so-unite:before { content: '\5b'; } /* '[' */
.neu-so-v-align-bottom:before { content: '\5c'; } /* '\' */
.neu-so-minus-front:before { content: '\5d'; } /* ']' */
.neu-so-new-artboard:before { content: '\5e'; } /* '^' */
.neu-so-paint-brush:before { content: '\5f'; } /* '_' */
.neu-so-v-align-center:before { content: '\60'; } /* '`' */
.neu-so-v-align-top:before { content: '\61'; } /* 'a' */
.neu-so-affiliate:before { content: '\62'; } /* 'b' */
.neu-so-bag:before { content: '\63'; } /* 'c' */
.neu-so-bank:before { content: '\64'; } /* 'd' */
.neu-so-bill:before { content: '\65'; } /* 'e' */
.neu-so-calculator:before { content: '\66'; } /* 'f' */
.neu-so-canvas:before { content: '\67'; } /* 'g' */
.neu-so-canvas-down:before { content: '\68'; } /* 'h' */
.neu-so-canvas-statistic:before { content: '\69'; } /* 'i' */
.neu-so-canvas-up:before { content: '\6a'; } /* 'j' */
.neu-so-cash:before { content: '\6b'; } /* 'k' */
.neu-so-chess-castle:before { content: '\6c'; } /* 'l' */
.neu-so-contact-book:before { content: '\6d'; } /* 'm' */
.neu-so-discount:before { content: '\6e'; } /* 'n' */
.neu-so-document:before { content: '\6f'; } /* 'o' */
.neu-so-document-tick:before { content: '\70'; } /* 'p' */
.neu-so-drawer:before { content: '\71'; } /* 'q' */
.neu-so-globe:before { content: '\72'; } /* 'r' */
.neu-so-gold:before { content: '\73'; } /* 's' */
.neu-so-law:before { content: '\74'; } /* 't' */
.neu-so-lightbulb:before { content: '\75'; } /* 'u' */
.neu-so-lightbulb-on:before { content: '\76'; } /* 'v' */
.neu-so-lightbulb-power:before { content: '\77'; } /* 'w' */
.neu-so-money:before { content: '\78'; } /* 'x' */
.neu-so-multi-level-marketing:before { content: '\79'; } /* 'y' */
.neu-so-pie-chart:before { content: '\7a'; } /* 'z' */
.neu-so-referral-2:before { content: '\7b'; } /* '{' */
.neu-so-statistic-board:before { content: '\7c'; } /* '|' */
.neu-so-strategy:before { content: '\7d'; } /* '}' */
.neu-so-tuxedo:before { content: '\7e'; } /* '~' */
.neu-blind-eyes:before { content: '\e800'; } /* '' */
.neu-disability:before { content: '\e801'; } /* '' */
.neu-enter-people:before { content: '\e802'; } /* '' */
.neu-eye-plus:before { content: '\e803'; } /* '' */
.neu-couple:before { content: '\e804'; } /* '' */
.neu-male:before { content: '\e805'; } /* '' */
.neu-people-gesture:before { content: '\e806'; } /* '' */
.neu-male-gesture:before { content: '\e807'; } /* '' */
.neu-accessibility:before { content: '\e808'; } /* '' */
.neu-people:before { content: '\e809'; } /* '' */
.neu-walk:before { content: '\e80a'; } /* '' */
.neu-escalator-down:before { content: '\e80b'; } /* '' */
.neu-escalator-up:before { content: '\e80c'; } /* '' */
.neu-female:before { content: '\e80d'; } /* '' */
.neu-female-gesture:before { content: '\e80e'; } /* '' */
.neu-bat:before { content: '\e80f'; } /* '' */
.neu-bear:before { content: '\e810'; } /* '' */
.neu-bone:before { content: '\e811'; } /* '' */
.neu-cat:before { content: '\e812'; } /* '' */
.neu-chicken:before { content: '\e813'; } /* '' */
.neu-cow:before { content: '\e814'; } /* '' */
.neu-crab:before { content: '\e815'; } /* '' */
.neu-corgi:before { content: '\e816'; } /* '' */
.neu-bird:before { content: '\e817'; } /* '' */
.neu-fish-bone:before { content: '\e818'; } /* '' */
.neu-fish:before { content: '\e819'; } /* '' */
.neu-monkey-2:before { content: '\e81a'; } /* '' */
.neu-monkey-3:before { content: '\e81b'; } /* '' */
.neu-monkey:before { content: '\e81c'; } /* '' */
.neu-panda:before { content: '\e81d'; } /* '' */
.neu-paw:before { content: '\e81e'; } /* '' */
.neu-pig:before { content: '\e81f'; } /* '' */
.neu-rabbit:before { content: '\e820'; } /* '' */
.neu-shark:before { content: '\e821'; } /* '' */
.neu-shrimp:before { content: '\e822'; } /* '' */
.neu-spider:before { content: '\e823'; } /* '' */
.neu-squid:before { content: '\e824'; } /* '' */
.neu-octopus:before { content: '\e825'; } /* '' */
.neu-turtle:before { content: '\e826'; } /* '' */
.neu-whale:before { content: '\e827'; } /* '' */
.neu-down-md:before { content: '\e828'; } /* '' */
.neu-down-lg:before { content: '\e829'; } /* '' */
.neu-up-md:before { content: '\e82a'; } /* '' */
.neu-up-lg:before { content: '\e82b'; } /* '' */
.neu-chevron-down:before { content: '\e82c'; } /* '' */
.neu-chevron:before { content: '\e82d'; } /* '' */
.neu-turn-right:before { content: '\e82e'; } /* '' */
.neu-down-sm:before { content: '\e82f'; } /* '' */
.neu-expand-lg:before { content: '\e830'; } /* '' */
.neu-expand-sm:before { content: '\e831'; } /* '' */
.neu-chevron-left:before { content: '\e832'; } /* '' */
.neu-left-lg:before { content: '\e833'; } /* '' */
.neu-left-md:before { content: '\e834'; } /* '' */
.neu-left-sm:before { content: '\e835'; } /* '' */
.neu-right-sm:before { content: '\e836'; } /* '' */
.neu-turn-left:before { content: '\e837'; } /* '' */
.neu-chevron-right:before { content: '\e838'; } /* '' */
.neu-right-md:before { content: '\e839'; } /* '' */
.neu-right-lg:before { content: '\e83a'; } /* '' */
.neu-chevron-up:before { content: '\e83b'; } /* '' */
.neu-up-sm:before { content: '\e83c'; } /* '' */
.neu-turn-right-2:before { content: '\e83d'; } /* '' */
.neu-expand-md:before { content: '\e83e'; } /* '' */
.neu-turn-left-2:before { content: '\e83f'; } /* '' */
.neu-refresh:before { content: '\e840'; } /* '' */
.neu-reload:before { content: '\e841'; } /* '' */
.neu-reload-left:before { content: '\e842'; } /* '' */
.neu-reload-right:before { content: '\e843'; } /* '' */
.neu-scroll-h:before { content: '\e844'; } /* '' */
.neu-scroll-v:before { content: '\e845'; } /* '' */
.neu-amusement-park:before { content: '\e846'; } /* '' */
.neu-apartment:before { content: '\e847'; } /* '' */
.neu-office:before { content: '\e848'; } /* '' */
.neu-building:before { content: '\e849'; } /* '' */
.neu-cabin-house:before { content: '\e84a'; } /* '' */
.neu-church:before { content: '\e84b'; } /* '' */
.neu-city:before { content: '\e84c'; } /* '' */
.neu-company:before { content: '\e84d'; } /* '' */
.neu-factory:before { content: '\e84e'; } /* '' */
.neu-landmark:before { content: '\e84f'; } /* '' */
.neu-monument:before { content: '\e850'; } /* '' */
.neu-mosque:before { content: '\e851'; } /* '' */
.neu-mosque-2:before { content: '\e852'; } /* '' */
.neu-ferris-wheel:before { content: '\e853'; } /* '' */
.neu-park:before { content: '\e854'; } /* '' */
.neu-police-station:before { content: '\e855'; } /* '' */
.neu-real-estate:before { content: '\e856'; } /* '' */
.neu-skyscrapers:before { content: '\e857'; } /* '' */
.neu-temple:before { content: '\e858'; } /* '' */
.neu-torii-gate:before { content: '\e859'; } /* '' */
.neu-affiliate:before { content: '\e85a'; } /* '' */
.neu-referral:before { content: '\e85b'; } /* '' */
.neu-multi-level-marketing:before { content: '\e85c'; } /* '' */
.neu-bank:before { content: '\e85d'; } /* '' */
.neu-bill:before { content: '\e85e'; } /* '' */
.neu-lightbulb-on:before { content: '\e85f'; } /* '' */
.neu-lightbulb-creative:before { content: '\e860'; } /* '' */
.neu-lightbulb:before { content: '\e861'; } /* '' */
.neu-lightbulb-power:before { content: '\e862'; } /* '' */
.neu-calculator:before { content: '\e863'; } /* '' */
.neu-canvas:before { content: '\e864'; } /* '' */
.neu-canvas-down:before { content: '\e865'; } /* '' */
.neu-canvas-up:before { content: '\e866'; } /* '' */
.neu-canvas-statistic:before { content: '\e867'; } /* '' */
.neu-cash:before { content: '\e868'; } /* '' */
.neu-chess-castle:before { content: '\e869'; } /* '' */
.neu-pie-chart:before { content: '\e86a'; } /* '' */
.neu-coin:before { content: '\e86b'; } /* '' */
.neu-coin-2:before { content: '\e86c'; } /* '' */
.neu-contact-book:before { content: '\e86d'; } /* '' */
.neu-discount:before { content: '\e86e'; } /* '' */
.neu-document-tick:before { content: '\e86f'; } /* '' */
.neu-document:before { content: '\e870'; } /* '' */
.neu-dollar:before { content: '\e871'; } /* '' */
.neu-drawer:before { content: '\e872'; } /* '' */
.neu-envelope:before { content: '\e873'; } /* '' */
.neu-euro:before { content: '\e874'; } /* '' */
.neu-globe:before { content: '\e875'; } /* '' */
.neu-gold:before { content: '\e876'; } /* '' */
.neu-hotel:before { content: '\e877'; } /* '' */
.neu-invest:before { content: '\e878'; } /* '' */
.neu-invoice:before { content: '\e879'; } /* '' */
.neu-law:before { content: '\e87a'; } /* '' */
.neu-money:before { content: '\e87b'; } /* '' */
.neu-poundsterling:before { content: '\e87c'; } /* '' */
.neu-referral-2:before { content: '\e87d'; } /* '' */
.neu-statistic-down:before { content: '\e87e'; } /* '' */
.neu-statistic-up:before { content: '\e87f'; } /* '' */
.neu-statistic-board:before { content: '\e880'; } /* '' */
.neu-success-plan:before { content: '\e881'; } /* '' */
.neu-strategy:before { content: '\e882'; } /* '' */
.neu-failed-plan:before { content: '\e883'; } /* '' */
.neu-tuxedo:before { content: '\e884'; } /* '' */
.neu-tuxedo-2:before { content: '\e885'; } /* '' */
.neu-wallet:before { content: '\e886'; } /* '' */
.neu-bag:before { content: '\e887'; } /* '' */
.neu-working-bag-2:before { content: '\e888'; } /* '' */
.neu-working-bag-3:before { content: '\e889'; } /* '' */
.neu-working-bag-4:before { content: '\e88a'; } /* '' */
.neu-yen:before { content: '\e88b'; } /* '' */
.neu-add-node:before { content: '\e88c'; } /* '' */
.neu-all-out:before { content: '\e88d'; } /* '' */
.neu-animation:before { content: '\e88e'; } /* '' */
.neu-binary:before { content: '\e88f'; } /* '' */
.neu-compare:before { content: '\e890'; } /* '' */
.neu-website-dashboard:before { content: '\e891'; } /* '' */
.neu-data-quality:before { content: '\e892'; } /* '' */
.neu-data-usage:before { content: '\e893'; } /* '' */
.neu-delete-node:before { content: '\e894'; } /* '' */
.neu-diagram-venn:before { content: '\e895'; } /* '' */
.neu-drag-indicator:before { content: '\e896'; } /* '' */
.neu-extension:before { content: '\e897'; } /* '' */
.neu-h-split:before { content: '\e898'; } /* '' */
.neu-mind-map:before { content: '\e899'; } /* '' */
.neu-nat:before { content: '\e89a'; } /* '' */
.neu-node:before { content: '\e89b'; } /* '' */
.neu-more-h:before { content: '\e89c'; } /* '' */
.neu-more-v:before { content: '\e89d'; } /* '' */
.neu-present-to-all:before { content: '\e89e'; } /* '' */
.neu-privacy-tips:before { content: '\e89f'; } /* '' */
.neu-qr-code:before { content: '\e8a0'; } /* '' */
.neu-select-all:before { content: '\e8a1'; } /* '' */
.neu-settings:before { content: '\e8a2'; } /* '' */
.neu-timeline:before { content: '\e8a3'; } /* '' */
.neu-toll:before { content: '\e8a4'; } /* '' */
.neu-radar:before { content: '\e8a5'; } /* '' */
.neu-v-split:before { content: '\e8a6'; } /* '' */
.neu-seo:before { content: '\e8a7'; } /* '' */
.neu-description:before { content: '\e8a8'; } /* '' */
.neu-workflow:before { content: '\e8a9'; } /* '' */
.neu-mesh-fill:before { content: '\e8aa'; } /* '' */
.neu-free-transform:before { content: '\e8ab'; } /* '' */
.neu-eyedropper-tool:before { content: '\e8ac'; } /* '' */
.neu-color:before { content: '\e8ad'; } /* '' */
.neu-bucket-paint:before { content: '\e8ae'; } /* '' */
.neu-palette:before { content: '\e8af'; } /* '' */
.neu-mathematical-anchor:before { content: '\e8b0'; } /* '' */
.neu-magic-wand:before { content: '\e8b1'; } /* '' */
.neu-pen-tool:before { content: '\e8b2'; } /* '' */
.neu-trim:before { content: '\e8b3'; } /* '' */
.neu-edit-path:before { content: '\e8b4'; } /* '' */
.neu-paint-brush:before { content: '\e8b5'; } /* '' */
.neu-compress:before { content: '\e8b6'; } /* '' */
.neu-transform:before { content: '\e8b7'; } /* '' */
.neu-select:before { content: '\e8b8'; } /* '' */
.neu-magnet:before { content: '\e8b9'; } /* '' */
.neu-blank:before { content: '\e8ba'; } /* '' */
.neu-folder-structure:before { content: '\e8bb'; } /* '' */
.neu-minus-front:before { content: '\e8bc'; } /* '' */
.neu-brush:before { content: '\e8bd'; } /* '' */
.neu-prism:before { content: '\e8be'; } /* '' */
.neu-pencil:before { content: '\e8bf'; } /* '' */
.neu-ruler-pencil:before { content: '\e8c0'; } /* '' */
.neu-structure:before { content: '\e8c1'; } /* '' */
.neu-file-structure:before { content: '\e8c2'; } /* '' */
.neu-picture:before { content: '\e8c3'; } /* '' */
.neu-capture:before { content: '\e8c4'; } /* '' */
.neu-divide:before { content: '\e8c5'; } /* '' */
.neu-cube:before { content: '\e8c6'; } /* '' */
.neu-hue-saturation:before { content: '\e8c7'; } /* '' */
.neu-tube:before { content: '\e8c8'; } /* '' */
.neu-img:before { content: '\e8c9'; } /* '' */
.neu-ruler:before { content: '\e8ca'; } /* '' */
.neu-h-align-left:before { content: '\e8cb'; } /* '' */
.neu-v-align-top:before { content: '\e8cc'; } /* '' */
.neu-v-align-bottom:before { content: '\e8cd'; } /* '' */
.neu-new-artboard:before { content: '\e8ce'; } /* '' */
.neu-v-align-center:before { content: '\e8cf'; } /* '' */
.neu-h-align-center:before { content: '\e8d0'; } /* '' */
.neu-h-align-right:before { content: '\e8d1'; } /* '' */
.neu-polygon:before { content: '\e8d2'; } /* '' */
.neu-intersect:before { content: '\e8d3'; } /* '' */
.neu-crop:before { content: '\e8d4'; } /* '' */
.neu-unite:before { content: '\e8d5'; } /* '' */
.neu-rounded-rectangle:before { content: '\e8d6'; } /* '' */
.neu-eclipse-1:before { content: '\e8d7'; } /* '' */
.neu-arrow:before { content: '\e8d8'; } /* '' */
.neu-triangle:before { content: '\e8d9'; } /* '' */
.neu-line:before { content: '\e8da'; } /* '' */
.neu-rectangle:before { content: '\e8db'; } /* '' */
.neu-air-conditioner:before { content: '\e8dc'; } /* '' */
.neu-bath-tube:before { content: '\e8dd'; } /* '' */
.neu-bed:before { content: '\e8de'; } /* '' */
.neu-library:before { content: '\e8df'; } /* '' */
.neu-chair:before { content: '\e8e0'; } /* '' */
.neu-chair-2:before { content: '\e8e1'; } /* '' */
.neu-drawer-2:before { content: '\e8e2'; } /* '' */
.neu-drawer-3:before { content: '\e8e3'; } /* '' */
.neu-table-drawer:before { content: '\e8e4'; } /* '' */
.neu-cupboard-shelf:before { content: '\e8e5'; } /* '' */
.neu-cupboard:before { content: '\e8e6'; } /* '' */
.neu-door:before { content: '\e8e7'; } /* '' */
.neu-lamp:before { content: '\e8e8'; } /* '' */
.neu-lamp-on:before { content: '\e8e9'; } /* '' */
.neu-mirror-make-up:before { content: '\e8ea'; } /* '' */
.neu-room-lamp:before { content: '\e8eb'; } /* '' */
.neu-shower:before { content: '\e8ec'; } /* '' */
.neu-sofa:before { content: '\e8ed'; } /* '' */
.neu-couch:before { content: '\e8ee'; } /* '' */
.neu-table:before { content: '\e8ef'; } /* '' */
.neu-tv-table:before { content: '\e8f0'; } /* '' */
.neu-toilet:before { content: '\e8f1'; } /* '' */
.neu-toilet-squad:before { content: '\e8f2'; } /* '' */
.neu-umbrella-table:before { content: '\e8f3'; } /* '' */
.neu-washing-machine:before { content: '\e8f4'; } /* '' */
.neu-shopping:before { content: '\e8f5'; } /* '' */
.neu-shopping-bags:before { content: '\e8f6'; } /* '' */
.neu-grocery-bags:before { content: '\e8f7'; } /* '' */
.neu-woman-bag:before { content: '\e8f8'; } /* '' */
.neu-barcode:before { content: '\e8f9'; } /* '' */
.neu-package:before { content: '\e8fa'; } /* '' */
.neu-shipping-box:before { content: '\e8fb'; } /* '' */
.neu-cvv:before { content: '\e8fc'; } /* '' */
.neu-credit-card:before { content: '\e8fd'; } /* '' */
.neu-credit-card-2:before { content: '\e8fe'; } /* '' */
.neu-grocery-bag:before { content: '\e8ff'; } /* '' */
.neu-invoice-paper:before { content: '\e900'; } /* '' */
.neu-marketplace-stars:before { content: '\e901'; } /* '' */
.neu-marketplace-love:before { content: '\e902'; } /* '' */
.neu-balance:before { content: '\e903'; } /* '' */
.neu-add-price:before { content: '\e904'; } /* '' */
.neu-delete-price:before { content: '\e905'; } /* '' */
.neu-price-tag:before { content: '\e906'; } /* '' */
.neu-price-tag-rotate:before { content: '\e907'; } /* '' */
.neu-remove-price:before { content: '\e908'; } /* '' */
.neu-shipping-car:before { content: '\e909'; } /* '' */
.neu-store-sign:before { content: '\e90a'; } /* '' */
.neu-ticket:before { content: '\e90b'; } /* '' */
.neu-top-up-balance:before { content: '\e90c'; } /* '' */
.neu-wallet-coin:before { content: '\e90d'; } /* '' */
.neu-emoji-blink:before { content: '\e90e'; } /* '' */
.neu-emoji-cheer-up:before { content: '\e90f'; } /* '' */
.neu-emoji-cool:before { content: '\e910'; } /* '' */
.neu-emoji-dead:before { content: '\e911'; } /* '' */
.neu-emoji-evil:before { content: '\e912'; } /* '' */
.neu-emoji-disappointed:before { content: '\e913'; } /* '' */
.neu-emoji-geek:before { content: '\e914'; } /* '' */
.neu-emoji-happy:before { content: '\e915'; } /* '' */
.neu-emoji-kids:before { content: '\e916'; } /* '' */
.neu-emoji-love:before { content: '\e917'; } /* '' */
.neu-emoji-mask:before { content: '\e918'; } /* '' */
.neu-emoji-mustache:before { content: '\e919'; } /* '' */
.neu-emoji-pirate:before { content: '\e91a'; } /* '' */
.neu-emoji-sad-2:before { content: '\e91b'; } /* '' */
.neu-emoji-sad:before { content: '\e91c'; } /* '' */
.neu-emoji-smile:before { content: '\e91d'; } /* '' */
.neu-emoji-smile-plain:before { content: '\e91e'; } /* '' */
.neu-emoji-cool-2:before { content: '\e91f'; } /* '' */
.neu-emoji-tongue:before { content: '\e920'; } /* '' */
.neu-emoji-wonder:before { content: '\e921'; } /* '' */
.neu-woman-bag-2:before { content: '\e922'; } /* '' */
.neu-woman-bag-3:before { content: '\e923'; } /* '' */
.neu-belt:before { content: '\e924'; } /* '' */
.neu-bikini:before { content: '\e925'; } /* '' */
.neu-boots:before { content: '\e926'; } /* '' */
.neu-bowties:before { content: '\e927'; } /* '' */
.neu-bra:before { content: '\e928'; } /* '' */
.neu-t-shirt:before { content: '\e929'; } /* '' */
.neu-diamond-jewel:before { content: '\e92a'; } /* '' */
.neu-wedding-dress:before { content: '\e92b'; } /* '' */
.neu-earrings:before { content: '\e92c'; } /* '' */
.neu-glasses:before { content: '\e92d'; } /* '' */
.neu-unique-glasses:before { content: '\e92e'; } /* '' */
.neu-hat:before { content: '\e92f'; } /* '' */
.neu-skullcaps:before { content: '\e930'; } /* '' */
.neu-high-heels:before { content: '\e931'; } /* '' */
.neu-hoodie:before { content: '\e932'; } /* '' */
.neu-no-iron:before { content: '\e933'; } /* '' */
.neu-iron:before { content: '\e934'; } /* '' */
.neu-pants:before { content: '\e935'; } /* '' */
.neu-short-pants:before { content: '\e936'; } /* '' */
.neu-basketball-jersey:before { content: '\e937'; } /* '' */
.neu-lipstick:before { content: '\e938'; } /* '' */
.neu-make-up:before { content: '\e939'; } /* '' */
.neu-loafers:before { content: '\e93a'; } /* '' */
.neu-necklace:before { content: '\e93b'; } /* '' */
.neu-ring:before { content: '\e93c'; } /* '' */
.neu-sandals:before { content: '\e93d'; } /* '' */
.neu-braidshirt:before { content: '\e93e'; } /* '' */
.neu-skirt:before { content: '\e93f'; } /* '' */
.neu-slipper:before { content: '\e940'; } /* '' */
.neu-sneakers:before { content: '\e941'; } /* '' */
.neu-socks:before { content: '\e942'; } /* '' */
.neu-tie:before { content: '\e943'; } /* '' */
.neu-tshirt:before { content: '\e944'; } /* '' */
.neu-tuxedo-fashion:before { content: '\e945'; } /* '' */
.neu-tuxedo-fashion-2:before { content: '\e946'; } /* '' */
.neu-tuxedo-fashion-3:before { content: '\e947'; } /* '' */
.neu-tuxedo-vallet:before { content: '\e948'; } /* '' */
.neu-tuxedo-formal:before { content: '\e949'; } /* '' */
.neu-underwear:before { content: '\e94a'; } /* '' */
.neu-underwear-2:before { content: '\e94b'; } /* '' */
.neu-vest:before { content: '\e94c'; } /* '' */
.neu-watch:before { content: '\e94d'; } /* '' */
.neu-woman-boot:before { content: '\e94e'; } /* '' */
.neu-add-bracket:before { content: '\e94f'; } /* '' */
.neu-add-folder:before { content: '\e950'; } /* '' */
.neu-book-open-2:before { content: '\e951'; } /* '' */
.neu-bookmark-book:before { content: '\e952'; } /* '' */
.neu-delete-folder:before { content: '\e953'; } /* '' */
.neu-file-tick:before { content: '\e954'; } /* '' */
.neu-file-down:before { content: '\e955'; } /* '' */
.neu-inbox:before { content: '\e956'; } /* '' */
.neu-inbox-full:before { content: '\e957'; } /* '' */
.neu-drawer-office:before { content: '\e958'; } /* '' */
.neu-add-file:before { content: '\e959'; } /* '' */
.neu-delete-file:before { content: '\e95a'; } /* '' */
.neu-file:before { content: '\e95b'; } /* '' */
.neu-faq-file:before { content: '\e95c'; } /* '' */
.neu-remove-file:before { content: '\e95d'; } /* '' */
.neu-alert-file:before { content: '\e95e'; } /* '' */
.neu-documents:before { content: '\e95f'; } /* '' */
.neu-folder-2:before { content: '\e960'; } /* '' */
.neu-blank-note:before { content: '\e961'; } /* '' */
.neu-note:before { content: '\e962'; } /* '' */
.neu-media-file:before { content: '\e963'; } /* '' */
.neu-remove-bracket:before { content: '\e964'; } /* '' */
.neu-remove-folder:before { content: '\e965'; } /* '' */
.neu-file-up:before { content: '\e966'; } /* '' */
.neu-folder-tick:before { content: '\e967'; } /* '' */
.neu-apple:before { content: '\e968'; } /* '' */
.neu-avocado:before { content: '\e969'; } /* '' */
.neu-bacon:before { content: '\e96a'; } /* '' */
.neu-meat:before { content: '\e96b'; } /* '' */
.neu-bottle:before { content: '\e96c'; } /* '' */
.neu-rice-bowl:before { content: '\e96d'; } /* '' */
.neu-bread:before { content: '\e96e'; } /* '' */
.neu-burger:before { content: '\e96f'; } /* '' */
.neu-cherry:before { content: '\e970'; } /* '' */
.neu-coffee-cup:before { content: '\e971'; } /* '' */
.neu-cone:before { content: '\e972'; } /* '' */
.neu-coffee:before { content: '\e973'; } /* '' */
.neu-hot-tea:before { content: '\e974'; } /* '' */
.neu-donut:before { content: '\e975'; } /* '' */
.neu-egg:before { content: '\e976'; } /* '' */
.neu-beer-glass:before { content: '\e977'; } /* '' */
.neu-glass-fill:before { content: '\e978'; } /* '' */
.neu-grape:before { content: '\e979'; } /* '' */
.neu-hotdog:before { content: '\e97a'; } /* '' */
.neu-ice-coffee:before { content: '\e97b'; } /* '' */
.neu-knife-fork:before { content: '\e97c'; } /* '' */
.neu-lemon:before { content: '\e97d'; } /* '' */
.neu-onigiri:before { content: '\e97e'; } /* '' */
.neu-black-pepper:before { content: '\e97f'; } /* '' */
.neu-pinneaple:before { content: '\e980'; } /* '' */
.neu-popsicle:before { content: '\e981'; } /* '' */
.neu-spoon-fork:before { content: '\e982'; } /* '' */
.neu-strawberry:before { content: '\e983'; } /* '' */
.neu-watermelon:before { content: '\e984'; } /* '' */
.neu-wine:before { content: '\e985'; } /* '' */
.neu-achievement:before { content: '\e986'; } /* '' */
.neu-block-toys:before { content: '\e987'; } /* '' */
.neu-bomb:before { content: '\e988'; } /* '' */
.neu-card-game:before { content: '\e989'; } /* '' */
.neu-chess-pion:before { content: '\e98a'; } /* '' */
.neu-controller:before { content: '\e98b'; } /* '' */
.neu-crown:before { content: '\e98c'; } /* '' */
.neu-diamond-game:before { content: '\e98d'; } /* '' */
.neu-fire:before { content: '\e98e'; } /* '' */
.neu-game-console:before { content: '\e98f'; } /* '' */
.neu-console-game:before { content: '\e990'; } /* '' */
.neu-pacman:before { content: '\e991'; } /* '' */
.neu-ghost:before { content: '\e992'; } /* '' */
.neu-helmet-game:before { content: '\e993'; } /* '' */
.neu-leaderboard:before { content: '\e994'; } /* '' */
.neu-mushroom:before { content: '\e995'; } /* '' */
.neu-pipe:before { content: '\e996'; } /* '' */
.neu-potion:before { content: '\e997'; } /* '' */
.neu-puzzle:before { content: '\e998'; } /* '' */
.neu-medal-game:before { content: '\e999'; } /* '' */
.neu-shield-game:before { content: '\e99a'; } /* '' */
.neu-skull-head:before { content: '\e99b'; } /* '' */
.neu-soul:before { content: '\e99c'; } /* '' */
.neu-sword:before { content: '\e99d'; } /* '' */
.neu-tetris:before { content: '\e99e'; } /* '' */
.neu-direction:before { content: '\e99f'; } /* '' */
.neu-hold:before { content: '\e9a0'; } /* '' */
.neu-move:before { content: '\e9a1'; } /* '' */
.neu-password-pattern:before { content: '\e9a2'; } /* '' */
.neu-pinch:before { content: '\e9a3'; } /* '' */
.neu-scan-barcode:before { content: '\e9a4'; } /* '' */
.neu-swipe-down:before { content: '\e9a5'; } /* '' */
.neu-swipe-left:before { content: '\e9a6'; } /* '' */
.neu-swipe-right:before { content: '\e9a7'; } /* '' */
.neu-swipe-up:before { content: '\e9a8'; } /* '' */
.neu-swipe-right-hand:before { content: '\e9a9'; } /* '' */
.neu-swipe-left-two-fingers:before { content: '\e9aa'; } /* '' */
.neu-swipe-right-two-fingers:before { content: '\e9ab'; } /* '' */
.neu-swipe-left-hand:before { content: '\e9ac'; } /* '' */
.neu-tap:before { content: '\e9ad'; } /* '' */
.neu-swipe-left-three-fingers:before { content: '\e9ae'; } /* '' */
.neu-swipe-right-three-fingers:before { content: '\e9af'; } /* '' */
.neu-touch:before { content: '\e9b0'; } /* '' */
.neu-touch-2:before { content: '\e9b1'; } /* '' */
.neu-expand-view:before { content: '\e9b2'; } /* '' */
.neu-balloon:before { content: '\e9b3'; } /* '' */
.neu-dog-bone:before { content: '\e9b4'; } /* '' */
.neu-bowtie:before { content: '\e9b5'; } /* '' */
.neu-candle:before { content: '\e9b6'; } /* '' */
.neu-coffin:before { content: '\e9b7'; } /* '' */
.neu-decoration:before { content: '\e9b8'; } /* '' */
.neu-easter-egg:before { content: '\e9b9'; } /* '' */
.neu-easter-egg-2:before { content: '\e9ba'; } /* '' */
.neu-easter-egg-3:before { content: '\e9bb'; } /* '' */
.neu-easter-egg-4:before { content: '\e9bc'; } /* '' */
.neu-easter-egg-5:before { content: '\e9bd'; } /* '' */
.neu-fireworks:before { content: '\e9be'; } /* '' */
.neu-new-year:before { content: '\e9bf'; } /* '' */
.neu-fox:before { content: '\e9c0'; } /* '' */
.neu-present:before { content: '\e9c1'; } /* '' */
.neu-glass:before { content: '\e9c2'; } /* '' */
.neu-glove:before { content: '\e9c3'; } /* '' */
.neu-grave:before { content: '\e9c4'; } /* '' */
.neu-christmas-lamp:before { content: '\e9c5'; } /* '' */
.neu-travel-bag:before { content: '\e9c6'; } /* '' */
.neu-witch:before { content: '\e9c7'; } /* '' */
.neu-pumpkin:before { content: '\e9c8'; } /* '' */
.neu-snowman:before { content: '\e9c9'; } /* '' */
.neu-socks-winter:before { content: '\e9ca'; } /* '' */
.neu-spider-web:before { content: '\e9cb'; } /* '' */
.neu-spider-insect:before { content: '\e9cc'; } /* '' */
.neu-christmas-tree:before { content: '\e9cd'; } /* '' */
.neu-umbrella-holiday:before { content: '\e9ce'; } /* '' */
.neu-volleyball:before { content: '\e9cf'; } /* '' */
.neu-zombie:before { content: '\e9d0'; } /* '' */
.neu-add-lg:before { content: '\e9d1'; } /* '' */
.neu-activity:before { content: '\e9d2'; } /* '' */
.neu-add-circle:before { content: '\e9d3'; } /* '' */
.neu-so-tuxedo-2:before { content: '\e9d4'; } /* '' */
.neu-so-wallet:before { content: '\e9d5'; } /* '' */
.neu-adjustment-h-md:before { content: '\e9d6'; } /* '' */
.neu-slider-h-lg:before { content: '\e9d7'; } /* '' */
.neu-adjustment-h-lg:before { content: '\e9d8'; } /* '' */
.neu-adjustment-v-lg:before { content: '\e9d9'; } /* '' */
.neu-slider-v-lg:before { content: '\e9da'; } /* '' */
.neu-slider-h-md:before { content: '\e9db'; } /* '' */
.neu-slider-v-md:before { content: '\e9dc'; } /* '' */
.neu-adjustment-v-md:before { content: '\e9dd'; } /* '' */
.neu-airplay:before { content: '\e9de'; } /* '' */
.neu-anchor:before { content: '\e9df'; } /* '' */
.neu-archive:before { content: '\e9e0'; } /* '' */
.neu-attachment:before { content: '\e9e1'; } /* '' */
.neu-attachment-rotate:before { content: '\e9e2'; } /* '' */
.neu-audio-ui:before { content: '\e9e3'; } /* '' */
.neu-shopping-bag-ui:before { content: '\e9e4'; } /* '' */
.neu-bar:before { content: '\e9e5'; } /* '' */
.neu-bar-2:before { content: '\e9e6'; } /* '' */
.neu-basket:before { content: '\e9e7'; } /* '' */
.neu-battery-charging:before { content: '\e9e8'; } /* '' */
.neu-battery-half:before { content: '\e9e9'; } /* '' */
.neu-battery-full:before { content: '\e9ea'; } /* '' */
.neu-bluetooth:before { content: '\e9eb'; } /* '' */
.neu-book-open:before { content: '\e9ec'; } /* '' */
.neu-book:before { content: '\e9ed'; } /* '' */
.neu-bookmark:before { content: '\e9ee'; } /* '' */
.neu-brightness:before { content: '\e9ef'; } /* '' */
.neu-browse:before { content: '\e9f0'; } /* '' */
.neu-add-call:before { content: '\e9f1'; } /* '' */
.neu-calling:before { content: '\e9f2'; } /* '' */
.neu-remove-call:before { content: '\e9f3'; } /* '' */
.neu-phone:before { content: '\e9f4'; } /* '' */
.neu-camera:before { content: '\e9f5'; } /* '' */
.neu-resolution:before { content: '\e9f6'; } /* '' */
.neu-cart:before { content: '\e9f7'; } /* '' */
.neu-add-to-cart:before { content: '\e9f8'; } /* '' */
.neu-cast:before { content: '\e9f9'; } /* '' */
.neu-chat-circle:before { content: '\e9fa'; } /* '' */
.neu-chat-square:before { content: '\e9fb'; } /* '' */
.neu-chat-circle-plain:before { content: '\e9fc'; } /* '' */
.neu-chat-square-plain:before { content: '\e9fd'; } /* '' */
.neu-chat-square-plain-2:before { content: '\e9fe'; } /* '' */
.neu-checkmark:before { content: '\e9ff'; } /* '' */
.neu-tick-circle:before { content: '\ea00'; } /* '' */
.neu-loading:before { content: '\ea01'; } /* '' */
.neu-alarm:before { content: '\ea02'; } /* '' */
.neu-clock:before { content: '\ea03'; } /* '' */
.neu-stopwatch:before { content: '\ea04'; } /* '' */
.neu-close-lg:before { content: '\ea05'; } /* '' */
.neu-cloud-ui:before { content: '\ea06'; } /* '' */
.neu-cmd:before { content: '\ea07'; } /* '' */
.neu-compass:before { content: '\ea08'; } /* '' */
.neu-copy:before { content: '\ea09'; } /* '' */
.neu-cursor:before { content: '\ea0a'; } /* '' */
.neu-paper-plane:before { content: '\ea0b'; } /* '' */
.neu-cut:before { content: '\ea0c'; } /* '' */
.neu-date:before { content: '\ea0d'; } /* '' */
.neu-delete:before { content: '\ea0e'; } /* '' */
.neu-detail:before { content: '\ea0f'; } /* '' */
.neu-detail-2:before { content: '\ea10'; } /* '' */
.neu-cd:before { content: '\ea11'; } /* '' */
.neu-download-bracket:before { content: '\ea12'; } /* '' */
.neu-download:before { content: '\ea13'; } /* '' */
.neu-mailbox:before { content: '\ea14'; } /* '' */
.neu-edit-ui-2:before { content: '\ea15'; } /* '' */
.neu-writing:before { content: '\ea16'; } /* '' */
.neu-edit-ui:before { content: '\ea17'; } /* '' */
.neu-email:before { content: '\ea18'; } /* '' */
.neu-emoji:before { content: '\ea19'; } /* '' */
.neu-power:before { content: '\ea1a'; } /* '' */
.neu-energy:before { content: '\ea1b'; } /* '' */
.neu-expand-content:before { content: '\ea1c'; } /* '' */
.neu-eye-off:before { content: '\ea1d'; } /* '' */
.neu-eye:before { content: '\ea1e'; } /* '' */
.neu-scanner-female:before { content: '\ea1f'; } /* '' */
.neu-scanner-profile:before { content: '\ea20'; } /* '' */
.neu-face-scan:before { content: '\ea21'; } /* '' */
.neu-document-ui:before { content: '\ea22'; } /* '' */
.neu-file-ui:before { content: '\ea23'; } /* '' */
.neu-filter-ascending:before { content: '\ea24'; } /* '' */
.neu-filter-descending:before { content: '\ea25'; } /* '' */
.neu-filter:before { content: '\ea26'; } /* '' */
.neu-fingerprint:before { content: '\ea27'; } /* '' */
.neu-flag:before { content: '\ea28'; } /* '' */
.neu-flag-2:before { content: '\ea29'; } /* '' */
.neu-folder-ui:before { content: '\ea2a'; } /* '' */
.neu-gift:before { content: '\ea2b'; } /* '' */
.neu-hamburger-menu:before { content: '\ea2c'; } /* '' */
.neu-hand:before { content: '\ea2d'; } /* '' */
.neu-information-triangle:before { content: '\ea2e'; } /* '' */
.neu-hold-hand-ui:before { content: '\ea2f'; } /* '' */
.neu-home-2:before { content: '\ea30'; } /* '' */
.neu-home-5:before { content: '\ea31'; } /* '' */
.neu-home-3:before { content: '\ea32'; } /* '' */
.neu-home-4:before { content: '\ea33'; } /* '' */
.neu-home:before { content: '\ea34'; } /* '' */
.neu-link:before { content: '\ea35'; } /* '' */
.neu-image:before { content: '\ea36'; } /* '' */
.neu-info:before { content: '\ea37'; } /* '' */
.neu-layer:before { content: '\ea38'; } /* '' */
.neu-layout:before { content: '\ea39'; } /* '' */
.neu-layout-2:before { content: '\ea3a'; } /* '' */
.neu-menu:before { content: '\ea3b'; } /* '' */
.neu-layout-3:before { content: '\ea3c'; } /* '' */
.neu-layout-4:before { content: '\ea3d'; } /* '' */
.neu-leaf-ui:before { content: '\ea3e'; } /* '' */
.neu-leaf-ui-2:before { content: '\ea3f'; } /* '' */
.neu-lifebuoy:before { content: '\ea40'; } /* '' */
.neu-external-link:before { content: '\ea41'; } /* '' */
.neu-link-rotate:before { content: '\ea42'; } /* '' */
.neu-todo-done:before { content: '\ea43'; } /* '' */
.neu-todo:before { content: '\ea44'; } /* '' */
.neu-todo-failed:before { content: '\ea45'; } /* '' */
.neu-loading-2:before { content: '\ea46'; } /* '' */
.neu-map:before { content: '\ea47'; } /* '' */
.neu-gps:before { content: '\ea48'; } /* '' */
.neu-lock-square:before { content: '\ea49'; } /* '' */
.neu-lock-circle:before { content: '\ea4a'; } /* '' */
.neu-login-bracket:before { content: '\ea4b'; } /* '' */
.neu-login:before { content: '\ea4c'; } /* '' */
.neu-logout-bracket:before { content: '\ea4d'; } /* '' */
.neu-logout:before { content: '\ea4e'; } /* '' */
.neu-loop:before { content: '\ea4f'; } /* '' */
.neu-love:before { content: '\ea50'; } /* '' */
.neu-mail:before { content: '\ea51'; } /* '' */
.neu-marketplace:before { content: '\ea52'; } /* '' */
.neu-option:before { content: '\ea53'; } /* '' */
.neu-minus-sm:before { content: '\ea54'; } /* '' */
.neu-minus-circle:before { content: '\ea55'; } /* '' */
.neu-minus-lg:before { content: '\ea56'; } /* '' */
.neu-notication-off:before { content: '\ea57'; } /* '' */
.neu-notifcation:before { content: '\ea58'; } /* '' */
.neu-notification-on:before { content: '\ea59'; } /* '' */
.neu-notification-bell:before { content: '\ea5a'; } /* '' */
.neu-numeric-password:before { content: '\ea5b'; } /* '' */
.neu-paste:before { content: '\ea5c'; } /* '' */
.neu-path:before { content: '\ea5d'; } /* '' */
.neu-pencil-ui-circle:before { content: '\ea5e'; } /* '' */
.neu-pencil-ui:before { content: '\ea5f'; } /* '' */
.neu-pencil-ui-2:before { content: '\ea60'; } /* '' */
.neu-pencil-ui-3:before { content: '\ea61'; } /* '' */
.neu-pie-chart-ui:before { content: '\ea62'; } /* '' */
.neu-pin:before { content: '\ea63'; } /* '' */
.neu-pin-location:before { content: '\ea64'; } /* '' */
.neu-add-sm:before { content: '\ea65'; } /* '' */
.neu-price-ui:before { content: '\ea66'; } /* '' */
.neu-printer:before { content: '\ea67'; } /* '' */
.neu-faq:before { content: '\ea68'; } /* '' */
.neu-seen:before { content: '\ea69'; } /* '' */
.neu-refresh-square:before { content: '\ea6a'; } /* '' */
.neu-reload-square:before { content: '\ea6b'; } /* '' */
.neu-reload-ui:before { content: '\ea6c'; } /* '' */
.neu-reload-square-ui:before { content: '\ea6d'; } /* '' */
.neu-reload-square-ui-2:before { content: '\ea6e'; } /* '' */
.neu-reload-ui-2:before { content: '\ea6f'; } /* '' */
.neu-resolution-ui:before { content: '\ea70'; } /* '' */
.neu-save:before { content: '\ea71'; } /* '' */
.neu-scroll-ui:before { content: '\ea72'; } /* '' */
.neu-find:before { content: '\ea73'; } /* '' */
.neu-magnifier-glass:before { content: '\ea74'; } /* '' */
.neu-zoom-in:before { content: '\ea75'; } /* '' */
.neu-zoom-out:before { content: '\ea76'; } /* '' */
.neu-search:before { content: '\ea77'; } /* '' */
.neu-seen-2:before { content: '\ea78'; } /* '' */
.neu-seen-3:before { content: '\ea79'; } /* '' */
.neu-server:before { content: '\ea7a'; } /* '' */
.neu-settings-ui:before { content: '\ea7b'; } /* '' */
.neu-share-bracket:before { content: '\ea7c'; } /* '' */
.neu-share:before { content: '\ea7d'; } /* '' */
.neu-shrink-content:before { content: '\ea7e'; } /* '' */
.neu-signal:before { content: '\ea7f'; } /* '' */
.neu-mobile-signal:before { content: '\ea80'; } /* '' */
.neu-adjustment-h-lg-2:before { content: '\ea81'; } /* '' */
.neu-adjustment-v-lg-2:before { content: '\ea82'; } /* '' */
.neu-snooze:before { content: '\ea83'; } /* '' */
.neu-speaker-ui:before { content: '\ea84'; } /* '' */
.neu-announcer:before { content: '\ea85'; } /* '' */
.neu-speedometer:before { content: '\ea86'; } /* '' */
.neu-stars:before { content: '\ea87'; } /* '' */
.neu-store:before { content: '\ea88'; } /* '' */
.neu-toggle:before { content: '\ea89'; } /* '' */
.neu-target:before { content: '\ea8a'; } /* '' */
.neu-like:before { content: '\ea8b'; } /* '' */
.neu-time-sand:before { content: '\ea8c'; } /* '' */
.neu-trash:before { content: '\ea8d'; } /* '' */
.neu-trash-bin:before { content: '\ea8e'; } /* '' */
.neu-unlock-circle:before { content: '\ea8f'; } /* '' */
.neu-unlock-square:before { content: '\ea90'; } /* '' */
.neu-upload-bracket:before { content: '\ea91'; } /* '' */
.neu-upload:before { content: '\ea92'; } /* '' */
.neu-warning-circle:before { content: '\ea93'; } /* '' */
.neu-drop:before { content: '\ea94'; } /* '' */
.neu-wifi:before { content: '\ea95'; } /* '' */
.neu-wifi-2:before { content: '\ea96'; } /* '' */
.neu-close-sm:before { content: '\ea97'; } /* '' */
.neu-close-circle:before { content: '\ea98'; } /* '' */
.neu-zoom-in-2:before { content: '\ea99'; } /* '' */
.neu-zoom-out-2:before { content: '\ea9a'; } /* '' */
.neu-apron:before { content: '\ea9b'; } /* '' */
.neu-grill:before { content: '\ea9c'; } /* '' */
.neu-bbq:before { content: '\ea9d'; } /* '' */
.neu-blender:before { content: '\ea9e'; } /* '' */
.neu-bowl:before { content: '\ea9f'; } /* '' */
.neu-bread-toaster:before { content: '\eaa0'; } /* '' */
.neu-bucket:before { content: '\eaa1'; } /* '' */
.neu-chef:before { content: '\eaa2'; } /* '' */
.neu-ulekan:before { content: '\eaa3'; } /* '' */
.neu-kettle:before { content: '\eaa4'; } /* '' */
.neu-dish:before { content: '\eaa5'; } /* '' */
.neu-glass-empty:before { content: '\eaa6'; } /* '' */
.neu-glass-empty-wine:before { content: '\eaa7'; } /* '' */
.neu-cheese-grater:before { content: '\eaa8'; } /* '' */
.neu-ketchup:before { content: '\eaa9'; } /* '' */
.neu-water-measure:before { content: '\eaaa'; } /* '' */
.neu-knife-kitchen:before { content: '\eaab'; } /* '' */
.neu-ladle:before { content: '\eaac'; } /* '' */
.neu-rice-cooker:before { content: '\eaad'; } /* '' */
.neu-microwave:before { content: '\eaae'; } /* '' */
.neu-orange-squeezer:before { content: '\eaaf'; } /* '' */
.neu-ingredients:before { content: '\eab0'; } /* '' */
.neu-pizza-roller:before { content: '\eab1'; } /* '' */
.neu-plate:before { content: '\eab2'; } /* '' */
.neu-refrigerator:before { content: '\eab3'; } /* '' */
.neu-food-scale:before { content: '\eab4'; } /* '' */
.neu-spatula:before { content: '\eab5'; } /* '' */
.neu-spatula-2:before { content: '\eab6'; } /* '' */
.neu-spatula-3:before { content: '\eab7'; } /* '' */
.neu-tissue:before { content: '\eab8'; } /* '' */
.neu-activity-circle:before { content: '\eab9'; } /* '' */
.neu-ambulance:before { content: '\eaba'; } /* '' */
.neu-single-band-aid:before { content: '\eabb'; } /* '' */
.neu-band-aid:before { content: '\eabc'; } /* '' */
.neu-blood:before { content: '\eabd'; } /* '' */
.neu-brain:before { content: '\eabe'; } /* '' */
.neu-capsule:before { content: '\eabf'; } /* '' */
.neu-tooth:before { content: '\eac0'; } /* '' */
.neu-dna:before { content: '\eac1'; } /* '' */
.neu-doctor-bag:before { content: '\eac2'; } /* '' */
.neu-doctor:before { content: '\eac3'; } /* '' */
.neu-medicine:before { content: '\eac4'; } /* '' */
.neu-drugs:before { content: '\eac5'; } /* '' */
.neu-heart-rate:before { content: '\eac6'; } /* '' */
.neu-hospital:before { content: '\eac7'; } /* '' */
.neu-hospital-building:before { content: '\eac8'; } /* '' */
.neu-intestine:before { content: '\eac9'; } /* '' */
.neu-medical-bill:before { content: '\eaca'; } /* '' */
.neu-lungs:before { content: '\eacb'; } /* '' */
.neu-man-symbol:before { content: '\eacc'; } /* '' */
.neu-medical-kit:before { content: '\eacd'; } /* '' */
.neu-medical-supply:before { content: '\eace'; } /* '' */
.neu-medical-circle:before { content: '\eacf'; } /* '' */
.neu-medical:before { content: '\ead0'; } /* '' */
.neu-optometry:before { content: '\ead1'; } /* '' */
.neu-molecule:before { content: '\ead2'; } /* '' */
.neu-particle:before { content: '\ead3'; } /* '' */
.neu-chemical:before { content: '\ead4'; } /* '' */
.neu-health:before { content: '\ead5'; } /* '' */
.neu-medical-scale:before { content: '\ead6'; } /* '' */
.neu-medical-scanner:before { content: '\ead7'; } /* '' */
.neu-stethoscope:before { content: '\ead8'; } /* '' */
.neu-syringe:before { content: '\ead9'; } /* '' */
.neu-ulcer:before { content: '\eada'; } /* '' */
.neu-woman-symbol:before { content: '\eadb'; } /* '' */
.neu-album:before { content: '\eadc'; } /* '' */
.neu-album-2:before { content: '\eadd'; } /* '' */
.neu-mic:before { content: '\eade'; } /* '' */
.neu-audio:before { content: '\eadf'; } /* '' */
.neu-audio-wave:before { content: '\eae0'; } /* '' */
.neu-music-beat:before { content: '\eae1'; } /* '' */
.neu-cassette:before { content: '\eae2'; } /* '' */
.neu-open-disc:before { content: '\eae3'; } /* '' */
.neu-earphone:before { content: '\eae4'; } /* '' */
.neu-equalizer:before { content: '\eae5'; } /* '' */
.neu-instrument-playlist:before { content: '\eae6'; } /* '' */
.neu-karaoke:before { content: '\eae7'; } /* '' */
.neu-mic-2:before { content: '\eae8'; } /* '' */
.neu-microphone:before { content: '\eae9'; } /* '' */
.neu-podcast:before { content: '\eaea'; } /* '' */
.neu-next-circle:before { content: '\eaeb'; } /* '' */
.neu-next:before { content: '\eaec'; } /* '' */
.neu-music-note:before { content: '\eaed'; } /* '' */
.neu-music-note-2:before { content: '\eaee'; } /* '' */
.neu-single-note:before { content: '\eaef'; } /* '' */
.neu-single-note-2:before { content: '\eaf0'; } /* '' */
.neu-pause-circle:before { content: '\eaf1'; } /* '' */
.neu-pause:before { content: '\eaf2'; } /* '' */
.neu-piano:before { content: '\eaf3'; } /* '' */
.neu-play-circle:before { content: '\eaf4'; } /* '' */
.neu-play:before { content: '\eaf5'; } /* '' */
.neu-playlist:before { content: '\eaf6'; } /* '' */
.neu-podcast-2:before { content: '\eaf7'; } /* '' */
.neu-previous-circle:before { content: '\eaf8'; } /* '' */
.neu-previous:before { content: '\eaf9'; } /* '' */
.neu-queue:before { content: '\eafa'; } /* '' */
.neu-radio-2:before { content: '\eafb'; } /* '' */
.neu-radio:before { content: '\eafc'; } /* '' */
.neu-radio-signal:before { content: '\eafd'; } /* '' */
.neu-speaker:before { content: '\eafe'; } /* '' */
.neu-speaker-2:before { content: '\eaff'; } /* '' */
.neu-announcer-2:before { content: '\eb00'; } /* '' */
.neu-stop-circle:before { content: '\eb01'; } /* '' */
.neu-stop:before { content: '\eb02'; } /* '' */
.neu-cassette-2:before { content: '\eb03'; } /* '' */
.neu-volume-max:before { content: '\eb04'; } /* '' */
.neu-volume-off:before { content: '\eb05'; } /* '' */
.neu-volume:before { content: '\eb06'; } /* '' */
.neu-volume-up:before { content: '\eb07'; } /* '' */
.neu-sound-wave:before { content: '\eb08'; } /* '' */
.neu-feather:before { content: '\eb09'; } /* '' */
.neu-fire-camp:before { content: '\eb0a'; } /* '' */
.neu-flame:before { content: '\eb0b'; } /* '' */
.neu-flower:before { content: '\eb0c'; } /* '' */
.neu-clover:before { content: '\eb0d'; } /* '' */
.neu-leaf:before { content: '\eb0e'; } /* '' */
.neu-leaf-2:before { content: '\eb0f'; } /* '' */
.neu-leaf-branch:before { content: '\eb10'; } /* '' */
.neu-lotus:before { content: '\eb11'; } /* '' */
.neu-maple-leaf:before { content: '\eb12'; } /* '' */
.neu-plant:before { content: '\eb13'; } /* '' */
.neu-seed:before { content: '\eb14'; } /* '' */
.neu-stone:before { content: '\eb15'; } /* '' */
.neu-rose-bud:before { content: '\eb16'; } /* '' */
.neu-rose:before { content: '\eb17'; } /* '' */
.neu-tea-leaf:before { content: '\eb18'; } /* '' */
.neu-tree:before { content: '\eb19'; } /* '' */
.neu-tree-branch:before { content: '\eb1a'; } /* '' */
.neu-tree-branch-2:before { content: '\eb1b'; } /* '' */
.neu-tree-branch-3:before { content: '\eb1c'; } /* '' */
.neu-tree-branch-4:before { content: '\eb1d'; } /* '' */
.neu-tree-branch-5:before { content: '\eb1e'; } /* '' */
.neu-tree-2:before { content: '\eb1f'; } /* '' */
.neu-wheat:before { content: '\eb20'; } /* '' */
.neu-wood:before { content: '\eb21'; } /* '' */
.neu-alien:before { content: '\eb22'; } /* '' */
.neu-asteroid:before { content: '\eb23'; } /* '' */
.neu-astronaut:before { content: '\eb24'; } /* '' */
.neu-blackhole:before { content: '\eb25'; } /* '' */
.neu-box:before { content: '\eb26'; } /* '' */
.neu-comet:before { content: '\eb27'; } /* '' */
.neu-earth:before { content: '\eb28'; } /* '' */
.neu-moon-landing:before { content: '\eb29'; } /* '' */
.neu-moon-space:before { content: '\eb2a'; } /* '' */
.neu-nuclear:before { content: '\eb2b'; } /* '' */
.neu-lab:before { content: '\eb2c'; } /* '' */
.neu-orbit:before { content: '\eb2d'; } /* '' */
.neu-pluto:before { content: '\eb2e'; } /* '' */
.neu-robot:before { content: '\eb2f'; } /* '' */
.neu-rocket:before { content: '\eb30'; } /* '' */
.neu-rocket-launch:before { content: '\eb31'; } /* '' */
.neu-spaceship:before { content: '\eb32'; } /* '' */
.neu-satellite:before { content: '\eb33'; } /* '' */
.neu-saturn:before { content: '\eb34'; } /* '' */
.neu-shooter:before { content: '\eb35'; } /* '' */
.neu-space-signal:before { content: '\eb36'; } /* '' */
.neu-sun-space:before { content: '\eb37'; } /* '' */
.neu-telescope:before { content: '\eb38'; } /* '' */
.neu-ufo:before { content: '\eb39'; } /* '' */
.neu-venus:before { content: '\eb3a'; } /* '' */
.neu-badminton-ball:before { content: '\eb3b'; } /* '' */
.neu-badminton:before { content: '\eb3c'; } /* '' */
.neu-baseball:before { content: '\eb3d'; } /* '' */
.neu-basketball:before { content: '\eb3e'; } /* '' */
.neu-basketball-net:before { content: '\eb3f'; } /* '' */
.neu-billiard:before { content: '\eb40'; } /* '' */
.neu-bowling-ball:before { content: '\eb41'; } /* '' */
.neu-bowling:before { content: '\eb42'; } /* '' */
.neu-boxing:before { content: '\eb43'; } /* '' */
.neu-car-wheel:before { content: '\eb44'; } /* '' */
.neu-cricket:before { content: '\eb45'; } /* '' */
.neu-energy-drink:before { content: '\eb46'; } /* '' */
.neu-sports-energy:before { content: '\eb47'; } /* '' */
.neu-fencing:before { content: '\eb48'; } /* '' */
.neu-finish-flag:before { content: '\eb49'; } /* '' */
.neu-flag-sports:before { content: '\eb4a'; } /* '' */
.neu-soccer-arena:before { content: '\eb4b'; } /* '' */
.neu-golf:before { content: '\eb4c'; } /* '' */
.neu-golf-stick:before { content: '\eb4d'; } /* '' */
.neu-gym:before { content: '\eb4e'; } /* '' */
.neu-rugby-helmet:before { content: '\eb4f'; } /* '' */
.neu-hockey:before { content: '\eb50'; } /* '' */
.neu-medal:before { content: '\eb51'; } /* '' */
.neu-badge:before { content: '\eb52'; } /* '' */
.neu-rugby:before { content: '\eb53'; } /* '' */
.neu-running:before { content: '\eb54'; } /* '' */
.neu-soccer:before { content: '\eb55'; } /* '' */
.neu-stadium:before { content: '\eb56'; } /* '' */
.neu-swimming-pool:before { content: '\eb57'; } /* '' */
.neu-tennis:before { content: '\eb58'; } /* '' */
.neu-tournament-bracket:before { content: '\eb59'; } /* '' */
.neu-trophy:before { content: '\eb5a'; } /* '' */
.neu-vitamin:before { content: '\eb5b'; } /* '' */
.neu-weightlifting:before { content: '\eb5c'; } /* '' */
.neu-winner:before { content: '\eb5d'; } /* '' */
.neu-smartphone:before { content: '\eb5e'; } /* '' */
.neu-smartphone-camera:before { content: '\eb5f'; } /* '' */
.neu-mobile:before { content: '\eb60'; } /* '' */
.neu-smart-lcd:before { content: '\eb61'; } /* '' */
.neu-monitor:before { content: '\eb62'; } /* '' */
.neu-smartphone-2:before { content: '\eb63'; } /* '' */
.neu-mobile-2:before { content: '\eb64'; } /* '' */
.neu-monitor-2:before { content: '\eb65'; } /* '' */
.neu-mouse:before { content: '\eb66'; } /* '' */
.neu-laptop-open:before { content: '\eb67'; } /* '' */
.neu-tablet:before { content: '\eb68'; } /* '' */
.neu-laptop:before { content: '\eb69'; } /* '' */
.neu-usb:before { content: '\eb6a'; } /* '' */
.neu-smartwatch:before { content: '\eb6b'; } /* '' */
.neu-retro-tv:before { content: '\eb6c'; } /* '' */
.neu-ipad:before { content: '\eb6d'; } /* '' */
.neu-drawing-tablet:before { content: '\eb6e'; } /* '' */
.neu-smartwatch-2:before { content: '\eb6f'; } /* '' */
.neu-db:before { content: '\eb70'; } /* '' */
.neu-drone:before { content: '\eb71'; } /* '' */
.neu-mobile-landscape-lock:before { content: '\eb72'; } /* '' */
.neu-android:before { content: '\eb73'; } /* '' */
.neu-processor:before { content: '\eb74'; } /* '' */
.neu-mobile-landscape:before { content: '\eb75'; } /* '' */
.neu-monitor-settings:before { content: '\eb76'; } /* '' */
.neu-mobile-settings:before { content: '\eb77'; } /* '' */
.neu-laptop-setting:before { content: '\eb78'; } /* '' */
.neu-tablet-settings:before { content: '\eb79'; } /* '' */
.neu-laptop-settings-2:before { content: '\eb7a'; } /* '' */
.neu-db-maintenance:before { content: '\eb7b'; } /* '' */
.neu-italic:before { content: '\eb7c'; } /* '' */
.neu-text:before { content: '\eb7d'; } /* '' */
.neu-bold:before { content: '\eb7e'; } /* '' */
.neu-justify:before { content: '\eb7f'; } /* '' */
.neu-align-center:before { content: '\eb80'; } /* '' */
.neu-align-left:before { content: '\eb81'; } /* '' */
.neu-paragraph:before { content: '\eb82'; } /* '' */
.neu-strikethrough:before { content: '\eb83'; } /* '' */
.neu-underline:before { content: '\eb84'; } /* '' */
.neu-font:before { content: '\eb85'; } /* '' */
.neu-align-right:before { content: '\eb86'; } /* '' */
.neu-bulleted-list:before { content: '\eb87'; } /* '' */
.neu-numbered-list:before { content: '\eb88'; } /* '' */
.neu-list:before { content: '\eb89'; } /* '' */
.neu-superscript:before { content: '\eb8a'; } /* '' */
.neu-subscript:before { content: '\eb8b'; } /* '' */
.neu-text-size:before { content: '\eb8c'; } /* '' */
.neu-gallery:before { content: '\eb8d'; } /* '' */
.neu-username:before { content: '\eb8e'; } /* '' */
.neu-paragraph-inline-left:before { content: '\eb8f'; } /* '' */
.neu-paragraph-inline-right:before { content: '\eb90'; } /* '' */
.neu-edit-text:before { content: '\eb91'; } /* '' */
.neu-quote:before { content: '\eb92'; } /* '' */
.neu-text-fill:before { content: '\eb93'; } /* '' */
.neu-code:before { content: '\eb94'; } /* '' */
.neu-text-spacing-h:before { content: '\eb95'; } /* '' */
.neu-text-spacing-v:before { content: '\eb96'; } /* '' */
.neu-spellcheck:before { content: '\eb97'; } /* '' */
.neu-bulleted-list-2:before { content: '\eb98'; } /* '' */
.neu-font-size:before { content: '\eb99'; } /* '' */
.neu-paragraph-capital:before { content: '\eb9a'; } /* '' */
.neu-svg-font:before { content: '\eb9b'; } /* '' */
.neu-keyboard:before { content: '\eb9c'; } /* '' */
.neu-show-keyboard:before { content: '\eb9d'; } /* '' */
.neu-hide-keyboard:before { content: '\eb9e'; } /* '' */
.neu-air-balloon:before { content: '\eb9f'; } /* '' */
.neu-airport:before { content: '\eba0'; } /* '' */
.neu-bike-2:before { content: '\eba1'; } /* '' */
.neu-bike:before { content: '\eba2'; } /* '' */
.neu-boat:before { content: '\eba3'; } /* '' */
.neu-bus:before { content: '\eba4'; } /* '' */
.neu-bus-front:before { content: '\eba5'; } /* '' */
.neu-car-wheel-2:before { content: '\eba6'; } /* '' */
.neu-car:before { content: '\eba7'; } /* '' */
.neu-car-front:before { content: '\eba8'; } /* '' */
.neu-flying-saucer:before { content: '\eba9'; } /* '' */
.neu-gas-station:before { content: '\ebaa'; } /* '' */
.neu-helicopter:before { content: '\ebab'; } /* '' */
.neu-helmet:before { content: '\ebac'; } /* '' */
.neu-helmet-race:before { content: '\ebad'; } /* '' */
.neu-motorcycle:before { content: '\ebae'; } /* '' */
.neu-parking:before { content: '\ebaf'; } /* '' */
.neu-bicycle:before { content: '\ebb0'; } /* '' */
.neu-millitary-plane:before { content: '\ebb1'; } /* '' */
.neu-ship:before { content: '\ebb2'; } /* '' */
.neu-skateboard:before { content: '\ebb3'; } /* '' */
.neu-street:before { content: '\ebb4'; } /* '' */
.neu-taxi:before { content: '\ebb5'; } /* '' */
.neu-train:before { content: '\ebb6'; } /* '' */
.neu-street-warning:before { content: '\ebb7'; } /* '' */
.neu-add-group:before { content: '\ebb8'; } /* '' */
.neu-add-profile:before { content: '\ebb9'; } /* '' */
.neu-baby:before { content: '\ebba'; } /* '' */
.neu-couple-love:before { content: '\ebbb'; } /* '' */
.neu-delete-profile:before { content: '\ebbc'; } /* '' */
.neu-edit-profile:before { content: '\ebbd'; } /* '' */
.neu-expand-user:before { content: '\ebbe'; } /* '' */
.neu-group-chat:before { content: '\ebbf'; } /* '' */
.neu-groups:before { content: '\ebc0'; } /* '' */
.neu-favorite-profile:before { content: '\ebc1'; } /* '' */
.neu-boy:before { content: '\ebc2'; } /* '' */
.neu-idea:before { content: '\ebc3'; } /* '' */
.neu-profile:before { content: '\ebc4'; } /* '' */
.neu-remove-group:before { content: '\ebc5'; } /* '' */
.neu-remove-profile:before { content: '\ebc6'; } /* '' */
.neu-stars-profile:before { content: '\ebc7'; } /* '' */
.neu-customer-service:before { content: '\ebc8'; } /* '' */
.neu-user-information:before { content: '\ebc9'; } /* '' */
.neu-user-location:before { content: '\ebca'; } /* '' */
.neu-user-setting:before { content: '\ebcb'; } /* '' */
.neu-user-card:before { content: '\ebcc'; } /* '' */
.neu-user-low:before { content: '\ebcd'; } /* '' */
.neu-user-up:before { content: '\ebce'; } /* '' */
.neu-user-circle:before { content: '\ebcf'; } /* '' */
.neu-user:before { content: '\ebd0'; } /* '' */
.neu-user-2:before { content: '\ebd1'; } /* '' */
.neu-profile-2:before { content: '\ebd2'; } /* '' */
.neu-speak:before { content: '\ebd3'; } /* '' */
.neu-verified-user:before { content: '\ebd4'; } /* '' */
.neu-female-user:before { content: '\ebd5'; } /* '' */
.neu-female-user-circle:before { content: '\ebd6'; } /* '' */
.neu-woman:before { content: '\ebd7'; } /* '' */
.neu-woman-profile:before { content: '\ebd8'; } /* '' */
.neu-female-user-2:before { content: '\ebd9'; } /* '' */
.neu-female-profile:before { content: '\ebda'; } /* '' */
.neu-wind-turbin:before { content: '\ebdb'; } /* '' */
.neu-day-rain:before { content: '\ebdc'; } /* '' */
.neu-night-rain:before { content: '\ebdd'; } /* '' */
.neu-day-storm:before { content: '\ebde'; } /* '' */
.neu-cloud-snow:before { content: '\ebdf'; } /* '' */
.neu-night-storm:before { content: '\ebe0'; } /* '' */
.neu-night-cloud:before { content: '\ebe1'; } /* '' */
.neu-day-cloud:before { content: '\ebe2'; } /* '' */
.neu-moon-stars:before { content: '\ebe3'; } /* '' */
.neu-humidity:before { content: '\ebe4'; } /* '' */
.neu-rainy:before { content: '\ebe5'; } /* '' */
.neu-night-rain-2:before { content: '\ebe6'; } /* '' */
.neu-rain:before { content: '\ebe7'; } /* '' */
.neu-sun:before { content: '\ebe8'; } /* '' */
.neu-umbrella:before { content: '\ebe9'; } /* '' */
.neu-night-storm-2:before { content: '\ebea'; } /* '' */
.neu-cloud-humid:before { content: '\ebeb'; } /* '' */
.neu-rainbow-cloud:before { content: '\ebec'; } /* '' */
.neu-cloud-rain:before { content: '\ebed'; } /* '' */
.neu-sunrise-2:before { content: '\ebee'; } /* '' */
.neu-sunset-down:before { content: '\ebef'; } /* '' */
.neu-cloud-rain-2:before { content: '\ebf0'; } /* '' */
.neu-night-cloud-2:before { content: '\ebf1'; } /* '' */
.neu-sunrise-up:before { content: '\ebf2'; } /* '' */
.neu-heavy-rain:before { content: '\ebf3'; } /* '' */
.neu-snow:before { content: '\ebf4'; } /* '' */
.neu-cloud-rainbow-2:before { content: '\ebf5'; } /* '' */
.neu-cloud-storm:before { content: '\ebf6'; } /* '' */
.neu-thermometer-hot:before { content: '\ebf7'; } /* '' */
.neu-scenery:before { content: '\ebf8'; } /* '' */
.neu-sunrise:before { content: '\ebf9'; } /* '' */
.neu-thermometer-low:before { content: '\ebfa'; } /* '' */
.neu-night:before { content: '\ebfb'; } /* '' */
.neu-thermometer:before { content: '\ebfc'; } /* '' */
.neu-thermometer-max:before { content: '\ebfd'; } /* '' */
.neu-rain-water:before { content: '\ebfe'; } /* '' */
.neu-tornado:before { content: '\ebff'; } /* '' */
.neu-eclipse:before { content: '\ec00'; } /* '' */
.neu-rainbow:before { content: '\ec01'; } /* '' */
.neu-wind:before { content: '\ec02'; } /* '' */
.neu-sunset:before { content: '\ec03'; } /* '' */
.neu-anemometer:before { content: '\ec04'; } /* '' */
.neu-moon:before { content: '\ec05'; } /* '' */
.neu-celsius:before { content: '\ec06'; } /* '' */
.neu-moon-half:before { content: '\ec07'; } /* '' */
.neu-cloud-weather:before { content: '\ec08'; } /* '' */
.neu-cloudy:before { content: '\ec09'; } /* '' */
.neu-fahrenheit:before { content: '\ec0a'; } /* '' */
.neu-thermometer-low-2:before { content: '\ec0b'; } /* '' */
.neu-sunset-2:before { content: '\ec0c'; } /* '' */
.neu-so-working-bag-2:before { content: '\ec0d'; } /* '' */
.neu-so-working-bag-3:before { content: '\ec0e'; } /* '' */
.neu-so-working-bag-4:before { content: '\ec0f'; } /* '' */
.neu-so-coin-2:before { content: '\ec10'; } /* '' */
.neu-so-animation:before { content: '\ec11'; } /* '' */
.neu-so-data-quality:before { content: '\ec12'; } /* '' */
.neu-so-description:before { content: '\ec13'; } /* '' */
.neu-so-extension:before { content: '\ec14'; } /* '' */
.neu-so-h-split:before { content: '\ec15'; } /* '' */
.neu-so-nat:before { content: '\ec16'; } /* '' */
.neu-so-present-to-all:before { content: '\ec17'; } /* '' */
.neu-so-privacy-tips:before { content: '\ec18'; } /* '' */
.neu-so-radar:before { content: '\ec19'; } /* '' */
.neu-so-seo:before { content: '\ec1a'; } /* '' */
.neu-so-toll:before { content: '\ec1b'; } /* '' */
.neu-so-v-split:before { content: '\ec1c'; } /* '' */
.neu-so-website-dashboard:before { content: '\ec1d'; } /* '' */
.neu-so-workflow:before { content: '\ec1e'; } /* '' */
.neu-so-select-all:before { content: '\ec1f'; } /* '' */
.neu-so-qr-code:before { content: '\ec20'; } /* '' */
.neu-so-settings:before { content: '\ec21'; } /* '' */
.neu-br-instagram-sq:before { content: '\ec22'; } /* '' */
.neu-br-kakaotalk:before { content: '\ec23'; } /* '' */
.neu-br-kakaotalk-sq:before { content: '\ec24'; } /* '' */
.neu-br-line:before { content: '\ec25'; } /* '' */
.neu-br-line-sq:before { content: '\ec26'; } /* '' */
.neu-br-linkedin:before { content: '\ec27'; } /* '' */
.neu-br-linkedin-sq:before { content: '\ec28'; } /* '' */
.neu-br-messenger:before { content: '\ec29'; } /* '' */
.neu-br-messenger-sq:before { content: '\ec2a'; } /* '' */
.neu-br-slack:before { content: '\ec2b'; } /* '' */
.neu-br-slack-sq:before { content: '\ec2c'; } /* '' */
.neu-br-snapchat-sq:before { content: '\ec2d'; } /* '' */
.neu-br-soundcloud:before { content: '\ec2e'; } /* '' */
.neu-br-soundcloud-sq:before { content: '\ec2f'; } /* '' */
.neu-br-telegram:before { content: '\ec30'; } /* '' */
.neu-br-telegram-sq:before { content: '\ec31'; } /* '' */
.neu-br-tiktok:before { content: '\ec32'; } /* '' */
.neu-br-wechat-sq:before { content: '\ec33'; } /* '' */
.neu-br-whatsapp:before { content: '\ec34'; } /* '' */
.neu-br-whatsapp-sq:before { content: '\ec35'; } /* '' */
.neu-so-hotel:before { content: '\ec36'; } /* '' */
.neu-so-invest:before { content: '\ec37'; } /* '' */
.neu-so-invoice:before { content: '\ec38'; } /* '' */
.neu-so-coin:before { content: '\ec39'; } /* '' */
.neu-so-lightbulb-creative:before { content: '\ec3a'; } /* '' */
.neu-so-all-out:before { content: '\ec3b'; } /* '' */
.neu-br-dribbble:before { content: '\ec3c'; } /* '' */
.neu-br-fb-sq:before { content: '\ec3d'; } /* '' */
.neu-br-instagram:before { content: '\ec3e'; } /* '' */
.neu-br-tiktok-sq:before { content: '\ec3f'; } /* '' */
.neu-br-twitter:before { content: '\ec40'; } /* '' */
.neu-br-twitter-sq:before { content: '\ec41'; } /* '' */
.neu-br-rss:before { content: '\ec42'; } /* '' */
.neu-br-rss-sq:before { content: '\ec43'; } /* '' */
.neu-br-youtube:before { content: '\ec44'; } /* '' */
.neu-br-github:before { content: '\ec45'; } /* '' */
.neu-br-product-hunt:before { content: '\ec46'; } /* '' */
.neu-so-add-file:before { content: '\ec47'; } /* '' */
.neu-so-add-folder:before { content: '\ec48'; } /* '' */
.neu-so-alert-file:before { content: '\ec49'; } /* '' */
.neu-so-blank-note:before { content: '\ec4a'; } /* '' */
.neu-so-bookmark-book:before { content: '\ec4b'; } /* '' */
.neu-so-book-open-2:before { content: '\ec4c'; } /* '' */
.neu-so-delete-file:before { content: '\ec4d'; } /* '' */
.neu-so-delete-folder:before { content: '\ec4e'; } /* '' */
.neu-so-documents:before { content: '\ec4f'; } /* '' */
.neu-so-drawer-office:before { content: '\ec50'; } /* '' */
.neu-so-faq-file:before { content: '\ec51'; } /* '' */
.neu-so-file:before { content: '\ec52'; } /* '' */
.neu-so-file-down:before { content: '\ec53'; } /* '' */
.neu-so-file-tick:before { content: '\ec54'; } /* '' */
.neu-so-file-up:before { content: '\ec55'; } /* '' */
.neu-so-folder-2:before { content: '\ec56'; } /* '' */
.neu-so-folder-tick:before { content: '\ec57'; } /* '' */
.neu-so-inbox:before { content: '\ec58'; } /* '' */
.neu-so-add-price:before { content: '\ec59'; } /* '' */
.neu-so-balance:before { content: '\ec5a'; } /* '' */
.neu-so-barcode:before { content: '\ec5b'; } /* '' */
.neu-so-credit-card:before { content: '\ec5c'; } /* '' */
.neu-so-credit-card-2:before { content: '\ec5d'; } /* '' */
.neu-so-cvv:before { content: '\ec5e'; } /* '' */
.neu-so-delete-price:before { content: '\ec5f'; } /* '' */
.neu-so-grocery-bag:before { content: '\ec60'; } /* '' */
.neu-so-invoice-paper:before { content: '\ec61'; } /* '' */
.neu-so-woman-bag:before { content: '\ec62'; } /* '' */
.neu-so-basketball-jersey:before { content: '\ec63'; } /* '' */
.neu-so-bikini:before { content: '\ec64'; } /* '' */
.neu-so-boots:before { content: '\ec65'; } /* '' */
.neu-so-bowties:before { content: '\ec66'; } /* '' */
.neu-so-bra:before { content: '\ec67'; } /* '' */
.neu-so-braidshirt:before { content: '\ec68'; } /* '' */
.neu-so-diamond-jewel:before { content: '\ec69'; } /* '' */
.neu-so-glasses:before { content: '\ec6a'; } /* '' */
.neu-so-skullcaps:before { content: '\ec6b'; } /* '' */
.neu-so-slipper:before { content: '\ec6c'; } /* '' */
.neu-so-sneakers:before { content: '\ec6d'; } /* '' */
.neu-so-socks:before { content: '\ec6e'; } /* '' */
.neu-so-tie:before { content: '\ec6f'; } /* '' */
.neu-so-tshirt:before { content: '\ec70'; } /* '' */
.neu-so-t-shirt:before { content: '\ec71'; } /* '' */
.neu-so-tuxedo-fashion:before { content: '\ec72'; } /* '' */
.neu-so-tuxedo-fashion-2:before { content: '\ec73'; } /* '' */
.neu-so-inbox-full:before { content: '\ec74'; } /* '' */
.neu-so-media-file:before { content: '\ec75'; } /* '' */
.neu-so-note:before { content: '\ec76'; } /* '' */
.neu-so-remove-file:before { content: '\ec77'; } /* '' */
.neu-so-remove-folder:before { content: '\ec78'; } /* '' */
.neu-so-apple:before { content: '\ec79'; } /* '' */
.neu-so-avocado:before { content: '\ec7a'; } /* '' */
.neu-so-bacon:before { content: '\ec7b'; } /* '' */
.neu-so-beer-glass:before { content: '\ec7c'; } /* '' */
.neu-so-black-pepper:before { content: '\ec7d'; } /* '' */
.neu-so-meat:before { content: '\ec7e'; } /* '' */
.neu-so-onigiri:before { content: '\ec7f'; } /* '' */
.neu-so-pinneaple:before { content: '\ec80'; } /* '' */
.neu-so-popsicle:before { content: '\ec81'; } /* '' */
.neu-so-rice-bowl:before { content: '\ec82'; } /* '' */
.neu-so-bottle:before { content: '\ec83'; } /* '' */
.neu-so-bread:before { content: '\ec84'; } /* '' */
.neu-so-burger:before { content: '\ec85'; } /* '' */
.neu-so-cherry:before { content: '\ec86'; } /* '' */
.neu-so-coffee:before { content: '\ec87'; } /* '' */
.neu-so-coffee-cup:before { content: '\ec88'; } /* '' */
.neu-so-cone:before { content: '\ec89'; } /* '' */
.neu-so-package:before { content: '\ec8a'; } /* '' */
.neu-so-price-tag:before { content: '\ec8b'; } /* '' */
.neu-so-price-tag-rotate:before { content: '\ec8c'; } /* '' */
.neu-so-remove-price:before { content: '\ec8d'; } /* '' */
.neu-so-shipping-box:before { content: '\ec8e'; } /* '' */
.neu-so-shipping-car:before { content: '\ec8f'; } /* '' */
.neu-so-shopping:before { content: '\ec90'; } /* '' */
.neu-so-store-sign:before { content: '\ec91'; } /* '' */
.neu-so-ticket:before { content: '\ec92'; } /* '' */
.neu-so-top-up-balance:before { content: '\ec93'; } /* '' */
.neu-so-wallet-coin:before { content: '\ec94'; } /* '' */
.neu-so-high-heels:before { content: '\ec95'; } /* '' */
.neu-so-iron:before { content: '\ec96'; } /* '' */
.neu-so-lipstick:before { content: '\ec97'; } /* '' */
.neu-so-loafers:before { content: '\ec98'; } /* '' */
.neu-so-make-up:before { content: '\ec99'; } /* '' */
.neu-so-necklace:before { content: '\ec9a'; } /* '' */
.neu-so-no-iron:before { content: '\ec9b'; } /* '' */
.neu-so-pants:before { content: '\ec9c'; } /* '' */
.neu-so-ring:before { content: '\ec9d'; } /* '' */
.neu-so-sandals:before { content: '\ec9e'; } /* '' */
.neu-so-short-pants:before { content: '\ec9f'; } /* '' */
.neu-so-tuxedo-fashion-3:before { content: '\eca0'; } /* '' */
.neu-so-tuxedo-formal:before { content: '\eca1'; } /* '' */
.neu-so-tuxedo-vallet:before { content: '\eca2'; } /* '' */
.neu-so-underwear:before { content: '\eca3'; } /* '' */
.neu-so-underwear-2:before { content: '\eca4'; } /* '' */
.neu-so-unique-glasses:before { content: '\eca5'; } /* '' */
.neu-so-vest:before { content: '\eca6'; } /* '' */
.neu-so-watch:before { content: '\eca7'; } /* '' */
.neu-so-wedding-dress:before { content: '\eca8'; } /* '' */
.neu-so-woman-bag-2:before { content: '\eca9'; } /* '' */
.neu-so-woman-bag-3:before { content: '\ecaa'; } /* '' */
.neu-so-woman-boot:before { content: '\ecab'; } /* '' */
.neu-so-donut:before { content: '\ecac'; } /* '' */
.neu-so-egg:before { content: '\ecad'; } /* '' */
.neu-so-glass-fill:before { content: '\ecae'; } /* '' */
.neu-so-grape:before { content: '\ecaf'; } /* '' */
.neu-so-hotdog:before { content: '\ecb0'; } /* '' */
.neu-so-ice-coffee:before { content: '\ecb1'; } /* '' */
.neu-so-knife-fork:before { content: '\ecb2'; } /* '' */
.neu-so-lemon:before { content: '\ecb3'; } /* '' */
.neu-so-spoon-fork:before { content: '\ecb4'; } /* '' */
.neu-so-strawberry:before { content: '\ecb5'; } /* '' */
.neu-so-air-conditioner:before { content: '\ecb6'; } /* '' */
.neu-so-bath-tube:before { content: '\ecb7'; } /* '' */
.neu-so-bed:before { content: '\ecb8'; } /* '' */
.neu-so-chair:before { content: '\ecb9'; } /* '' */
.neu-so-chair-2:before { content: '\ecba'; } /* '' */
.neu-so-couch:before { content: '\ecbb'; } /* '' */
.neu-so-cupboard:before { content: '\ecbc'; } /* '' */
.neu-so-cupboard-shelf:before { content: '\ecbd'; } /* '' */
.neu-so-door:before { content: '\ecbe'; } /* '' */
.neu-so-drawer-2:before { content: '\ecbf'; } /* '' */
.neu-so-drawer-3:before { content: '\ecc0'; } /* '' */
.neu-so-lamp:before { content: '\ecc1'; } /* '' */
.neu-so-lamp-on:before { content: '\ecc2'; } /* '' */
.neu-so-library:before { content: '\ecc3'; } /* '' */
.neu-so-mirror-make-up:before { content: '\ecc4'; } /* '' */
.neu-so-room-lamp:before { content: '\ecc5'; } /* '' */
.neu-so-shower:before { content: '\ecc6'; } /* '' */
.neu-so-sofa:before { content: '\ecc7'; } /* '' */
.neu-so-table:before { content: '\ecc8'; } /* '' */
.neu-so-table-drawer:before { content: '\ecc9'; } /* '' */
.neu-so-toilet:before { content: '\ecca'; } /* '' */
.neu-so-toilet-squad:before { content: '\eccb'; } /* '' */
.neu-so-tv-table:before { content: '\eccc'; } /* '' */
.neu-so-umbrella-table:before { content: '\eccd'; } /* '' */
.neu-so-washing-machine:before { content: '\ecce'; } /* '' */
.neu-so-block-toys:before { content: '\eccf'; } /* '' */
.neu-so-bomb:before { content: '\ecd0'; } /* '' */
.neu-so-card-game:before { content: '\ecd1'; } /* '' */
.neu-so-chess-pion:before { content: '\ecd2'; } /* '' */
.neu-so-console-game:before { content: '\ecd3'; } /* '' */
.neu-so-controller:before { content: '\ecd4'; } /* '' */
.neu-so-crown:before { content: '\ecd5'; } /* '' */
.neu-so-diamond-game:before { content: '\ecd6'; } /* '' */
.neu-so-fire:before { content: '\ecd7'; } /* '' */
.neu-so-game-console:before { content: '\ecd8'; } /* '' */
.neu-so-ghost:before { content: '\ecd9'; } /* '' */
.neu-so-helmet-game:before { content: '\ecda'; } /* '' */
.neu-so-leaderboard:before { content: '\ecdb'; } /* '' */
.neu-so-medal-game:before { content: '\ecdc'; } /* '' */
.neu-so-mushroom:before { content: '\ecdd'; } /* '' */
.neu-so-pacman:before { content: '\ecde'; } /* '' */
.neu-so-pipe:before { content: '\ecdf'; } /* '' */
.neu-so-puzzle:before { content: '\ece0'; } /* '' */
.neu-so-shield-game:before { content: '\ece1'; } /* '' */
.neu-so-skull-head:before { content: '\ece2'; } /* '' */
.neu-so-soul:before { content: '\ece3'; } /* '' */
.neu-so-sword:before { content: '\ece4'; } /* '' */
.neu-so-tetris:before { content: '\ece5'; } /* '' */
.neu-so-direction:before { content: '\ece6'; } /* '' */
.neu-so-hold:before { content: '\ece7'; } /* '' */
.neu-so-password-pattern:before { content: '\ece8'; } /* '' */
.neu-so-swipe-left-hand:before { content: '\ece9'; } /* '' */
.neu-so-swipe-left-three-fingers:before { content: '\ecea'; } /* '' */
.neu-so-swipe-left-two-fingers:before { content: '\eceb'; } /* '' */
.neu-so-swipe-right-hand:before { content: '\ecec'; } /* '' */
.neu-so-swipe-right-three-fingers:before { content: '\eced'; } /* '' */
.neu-so-swipe-right-two-fingers:before { content: '\ecee'; } /* '' */
.neu-so-tap:before { content: '\ecef'; } /* '' */
.neu-so-touch:before { content: '\ecf0'; } /* '' */
.neu-so-touch-2:before { content: '\ecf1'; } /* '' */
.neu-so-balloon:before { content: '\ecf2'; } /* '' */
.neu-so-candle:before { content: '\ecf3'; } /* '' */
.neu-so-christmas-lamp:before { content: '\ecf4'; } /* '' */
.neu-so-christmas-tree:before { content: '\ecf5'; } /* '' */
.neu-so-coffin:before { content: '\ecf6'; } /* '' */
.neu-so-decoration:before { content: '\ecf7'; } /* '' */
.neu-so-dog-bone:before { content: '\ecf8'; } /* '' */
.neu-so-easter-egg:before { content: '\ecf9'; } /* '' */
.neu-so-easter-egg-2:before { content: '\ecfa'; } /* '' */
.neu-so-easter-egg-3:before { content: '\ecfb'; } /* '' */
.neu-so-easter-egg-4:before { content: '\ecfc'; } /* '' */
.neu-so-easter-egg-5:before { content: '\ecfd'; } /* '' */
.neu-so-fox:before { content: '\ecfe'; } /* '' */
.neu-so-glass:before { content: '\ecff'; } /* '' */
.neu-so-glove:before { content: '\ed00'; } /* '' */
.neu-so-grave:before { content: '\ed01'; } /* '' */
.neu-so-present:before { content: '\ed02'; } /* '' */
.neu-so-travel-bag:before { content: '\ed03'; } /* '' */
.neu-so-umbrella-holiday:before { content: '\ed04'; } /* '' */
.neu-so-volleyball:before { content: '\ed05'; } /* '' */
.neu-so-witch:before { content: '\ed06'; } /* '' */
.neu-so-zombie:before { content: '\ed07'; } /* '' */
.neu-so-pumpkin:before { content: '\ed08'; } /* '' */
.neu-so-socks-winter:before { content: '\ed09'; } /* '' */
.neu-so-spider-insect:before { content: '\ed0a'; } /* '' */
.neu-so-add-call:before { content: '\ed0b'; } /* '' */
.neu-so-add-circle:before { content: '\ed0c'; } /* '' */
.neu-so-add-to-cart:before { content: '\ed0d'; } /* '' */
.neu-so-airplay:before { content: '\ed0e'; } /* '' */
.neu-so-announcer:before { content: '\ed0f'; } /* '' */
.neu-so-archive:before { content: '\ed10'; } /* '' */
.neu-so-basket:before { content: '\ed11'; } /* '' */
.neu-so-book:before { content: '\ed12'; } /* '' */
.neu-so-bookmark:before { content: '\ed13'; } /* '' */
.neu-so-book-open:before { content: '\ed14'; } /* '' */
.neu-so-browse:before { content: '\ed15'; } /* '' */
.neu-so-camera:before { content: '\ed16'; } /* '' */
.neu-so-clock:before { content: '\ed17'; } /* '' */
.neu-so-close-circle:before { content: '\ed18'; } /* '' */
.neu-so-cloud-ui:before { content: '\ed19'; } /* '' */
.neu-so-compass:before { content: '\ed1a'; } /* '' */
.neu-so-copy:before { content: '\ed1b'; } /* '' */
.neu-so-cursor:before { content: '\ed1c'; } /* '' */
.neu-so-date:before { content: '\ed1d'; } /* '' */
.neu-so-delete:before { content: '\ed1e'; } /* '' */
.neu-so-document-ui:before { content: '\ed1f'; } /* '' */
.neu-so-drop:before { content: '\ed20'; } /* '' */
.neu-so-edit-ui:before { content: '\ed21'; } /* '' */
.neu-so-email:before { content: '\ed22'; } /* '' */
.neu-so-flag:before { content: '\ed23'; } /* '' */
.neu-so-flag-2:before { content: '\ed24'; } /* '' */
.neu-so-folder-ui:before { content: '\ed25'; } /* '' */
.neu-so-gift:before { content: '\ed26'; } /* '' */
.neu-so-gps:before { content: '\ed27'; } /* '' */
.neu-so-home:before { content: '\ed28'; } /* '' */
.neu-so-home-2:before { content: '\ed29'; } /* '' */
.neu-so-home-3:before { content: '\ed2a'; } /* '' */
.neu-so-home-4:before { content: '\ed2b'; } /* '' */
.neu-so-home-5:before { content: '\ed2c'; } /* '' */
.neu-so-image:before { content: '\ed2d'; } /* '' */
.neu-so-info:before { content: '\ed2e'; } /* '' */
.neu-so-like:before { content: '\ed2f'; } /* '' */
.neu-so-lock-circle:before { content: '\ed30'; } /* '' */
.neu-so-lock-square:before { content: '\ed31'; } /* '' */
.neu-so-love:before { content: '\ed32'; } /* '' */
.neu-so-mail:before { content: '\ed33'; } /* '' */
.neu-so-mailbox:before { content: '\ed34'; } /* '' */
.neu-so-map:before { content: '\ed35'; } /* '' */
.neu-so-menu:before { content: '\ed36'; } /* '' */
.neu-so-minus-circle:before { content: '\ed37'; } /* '' */
.neu-so-mobile-signal:before { content: '\ed38'; } /* '' */
.neu-so-notication-off:before { content: '\ed39'; } /* '' */
.neu-so-notification-bell:before { content: '\ed3a'; } /* '' */
.neu-so-pie-chart-ui:before { content: '\ed3b'; } /* '' */
.neu-so-pin:before { content: '\ed3c'; } /* '' */
.neu-so-pin-location:before { content: '\ed3d'; } /* '' */
.neu-so-power:before { content: '\ed3e'; } /* '' */
.neu-so-price-ui:before { content: '\ed3f'; } /* '' */
.neu-so-printer:before { content: '\ed40'; } /* '' */
.neu-so-remove-call:before { content: '\ed41'; } /* '' */
.neu-so-resolution:before { content: '\ed42'; } /* '' */
.neu-so-resolution-ui:before { content: '\ed43'; } /* '' */
.neu-so-save:before { content: '\ed44'; } /* '' */
.neu-so-scanner-female:before { content: '\ed45'; } /* '' */
.neu-so-scanner-profile:before { content: '\ed46'; } /* '' */
.neu-so-shopping-bag-ui:before { content: '\ed47'; } /* '' */
.neu-so-shrink-content:before { content: '\ed48'; } /* '' */
.neu-so-slider-h-lg:before { content: '\ed49'; } /* '' */
.neu-so-slider-h-md:before { content: '\ed4a'; } /* '' */
.neu-so-slider-v-lg:before { content: '\ed4b'; } /* '' */
.neu-so-slider-v-md:before { content: '\ed4c'; } /* '' */
.neu-so-snooze:before { content: '\ed4d'; } /* '' */
.neu-so-speaker-ui:before { content: '\ed4e'; } /* '' */
.neu-so-speedometer:before { content: '\ed4f'; } /* '' */
.neu-so-stars:before { content: '\ed50'; } /* '' */
.neu-so-stopwatch:before { content: '\ed51'; } /* '' */
.neu-so-store:before { content: '\ed52'; } /* '' */
.neu-so-writing:before { content: '\ed53'; } /* '' */
.neu-so-zoom-in-2:before { content: '\ed54'; } /* '' */
.neu-so-zoom-out-2:before { content: '\ed55'; } /* '' */
.neu-so-cart:before { content: '\ed56'; } /* '' */
.neu-so-cd:before { content: '\ed57'; } /* '' */
.neu-so-chat-circle:before { content: '\ed58'; } /* '' */
.neu-so-chat-circle-plain:before { content: '\ed59'; } /* '' */
.neu-so-chat-square:before { content: '\ed5a'; } /* '' */
.neu-so-chat-square-plain:before { content: '\ed5b'; } /* '' */
.neu-so-chat-square-plain-2:before { content: '\ed5c'; } /* '' */
.neu-so-checkmark:before { content: '\ed5d'; } /* '' */
.neu-so-energy:before { content: '\ed5e'; } /* '' */
.neu-so-expand-content:before { content: '\ed5f'; } /* '' */
.neu-so-eye:before { content: '\ed60'; } /* '' */
.neu-so-eye-off:before { content: '\ed61'; } /* '' */
.neu-so-faq:before { content: '\ed62'; } /* '' */
.neu-so-file-ui:before { content: '\ed63'; } /* '' */
.neu-so-filter:before { content: '\ed64'; } /* '' */
.neu-so-find:before { content: '\ed65'; } /* '' */
.neu-so-information-triangle:before { content: '\ed66'; } /* '' */
.neu-so-layer:before { content: '\ed67'; } /* '' */
.neu-so-layout:before { content: '\ed68'; } /* '' */
.neu-so-layout-2:before { content: '\ed69'; } /* '' */
.neu-so-layout-3:before { content: '\ed6a'; } /* '' */
.neu-so-layout-4:before { content: '\ed6b'; } /* '' */
.neu-so-leaf-ui:before { content: '\ed6c'; } /* '' */
.neu-so-leaf-ui-2:before { content: '\ed6d'; } /* '' */
.neu-so-notification-on:before { content: '\ed6e'; } /* '' */
.neu-so-paste:before { content: '\ed6f'; } /* '' */
.neu-so-path:before { content: '\ed70'; } /* '' */
.neu-so-pencil-ui:before { content: '\ed71'; } /* '' */
.neu-so-pencil-ui-2:before { content: '\ed72'; } /* '' */
.neu-so-pencil-ui-3:before { content: '\ed73'; } /* '' */
.neu-so-pencil-ui-circle:before { content: '\ed74'; } /* '' */
.neu-so-phone:before { content: '\ed75'; } /* '' */
.neu-so-server:before { content: '\ed76'; } /* '' */
.neu-so-share:before { content: '\ed77'; } /* '' */
.neu-so-alarm:before { content: '\ed78'; } /* '' */
.neu-so-loading-2:before { content: '\ed79'; } /* '' */
.neu-so-calling:before { content: '\ed7a'; } /* '' */
.neu-so-settings-ui:before { content: '\ed7b'; } /* '' */
.neu-so-lifebuoy:before { content: '\ed7c'; } /* '' */
.neu-so-brightness:before { content: '\ed7d'; } /* '' */
.neu-so-tick-circle:before { content: '\ed7e'; } /* '' */
.neu-so-time-sand:before { content: '\ed7f'; } /* '' */
.neu-so-toggle:before { content: '\ed80'; } /* '' */
.neu-so-trash:before { content: '\ed81'; } /* '' */
.neu-so-trash-bin:before { content: '\ed82'; } /* '' */
.neu-so-unlock-circle:before { content: '\ed83'; } /* '' */
.neu-so-unlock-square:before { content: '\ed84'; } /* '' */
.neu-so-warning-circle:before { content: '\ed85'; } /* '' */